import * as React from "react";


export class TermoParaImpressao extends React.PureComponent {

    constructor(props) {
        super(props);
        this.urlLogo = sessionStorage.getItem('url_logo');
    }

    render() {
        return (
            <div className="container d-none d-print-block">
                <div className="text-center">
                    <img src={this.urlLogo} alt=""/>
                </div>

                <div className="d-none d-print-block mt-5"
                     dangerouslySetInnerHTML={{__html: this.props.html}}>
                </div>
            </div>
        );
    }
}