import Usuariologado from "../../componentes/layoutBase/usuariologado";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../componentes/loading";
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import {Button, Modal} from "react-bootstrap";
import MensagemDeErro from "../../componentes/mensagemDeErro";
import ModalAvisoSemPermissao from "../../componentes/modal.avisos.sempermissao";
import CurrencyInput from "react-currency-input-field";
import MensagemParaTabelaSemDados from "../../componentes/mensagemParaTabelaSemDados";
import DatePicker from "react-date-picker";
import InputMask from "react-input-mask";
import Cidade from "../../componentes/cidade";
import {useNavigate} from "react-router-dom";

export default function Configurations() {
    const navigate = useNavigate();

    useEffect(() => {
        goToExpenseType();
    }, []);

    function goToExpenseType() {
        navigate("/tipo-de-despesa");
    }

    return (
        <main className="main" id="top">
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content">
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="row justify-content-between align-items-center">
                                <div className="col col-lg-8 mb-4">
                                    <h5 className="mb-0">Configurações Financeiras</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light">
                            <div className="col-12 col-sm-auto ms-auto">
                                <button className="btn btn-falcon-primary m-md-1" type="button">Tipo De Despesa</button>
                                <button className="btn btn-falcon-primary m-md-1" type="button">Tipo De Receita</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );

}