export default function StatusDaConsulta(props) {

    return (
        <div>
            <div hidden={props.status != 'CONFIRMADA'}>
                <span className="badge badge rounded-pill d-block badge-subtle-success">
                    Confirmada<span className="ms-1 fas fa-check" data-fa-transform="shrink-2"/></span>
            </div>
            <div hidden={props.status != 'REALIZADA'}>
                <span className="badge badge rounded-pill d-block badge-subtle-success">
                    Realizada<span className="ms-1 fas fa-check" data-fa-transform="shrink-2"/></span>
            </div>
            <div hidden={props.status != 'DESMARCADA'}>
                <span className="badge badge rounded-pill d-block badge-subtle-secondary">
                    Desmarcada<span className="ms-1 fas fa-ban" data-fa-transform="shrink-2"/></span>
            </div>
            <div hidden={props.status != 'REAGENDADA'}>
                <span className="badge badge rounded-pill d-block badge-subtle-primary">
                    Reagendada<span className="ms-1 fas fa-redo" data-fa-transform="shrink-2"/></span>
            </div>
        </div>
    );
}