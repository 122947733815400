import Loading from "../../componentes/loading";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import Usuariologado from "../../componentes/layoutBase/usuariologado";
import {useEffect, useState} from "react";
import axios from "axios";
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import {Modal} from "react-bootstrap";
import MensagemDeErro from "../../componentes/mensagemDeErro";

export default function Clinica() {

    const URL_PADRAO = require('../../api/url.json').api;
    const [isLoading, setLoading] = useState(true);
    const [clinicas, setClinicas] = useState([]);
    const [nomeDaClinica, setNomeDaClinica] = useState('');
    const [tenantsDisponiveis, setTenantsDisponiveis] = useState([]);
    const [tenantSelecionado, setTenantSelecionado] = useState('');
    const [planoSelecionado, setPlanoSelecionado] = useState('');
    const [planosDisponiveis, setPlanosDisponiveis] = useState([]);
    const [exibeMensagemDeSucesso, setExibeMensagemDeSucesso] = useState(false);
    const [mensagemDeSucesso, setMensagemDeSucesso] = useState('');
    const [exibeMensagemDeErro, setExibeMensagemDeErro] = useState(false);
    const [mensagemDeErro, setMensagemDeErro] = useState('');

    const [exibeModalDeUsuarios, setExibeModalDeUsuarios] = useState(false);
    const [usuariosDaClinica, setUsuariosDaClinica] = useState([]);
    const [nomeDoUsuario, setNomeDoUsuario] = useState('');
    const [login, setLogin] = useState('');
    const [idDaClinicaSelecionada, setIdDaClinicaSelecionada] = useState(0);

    const api = axios.create({
        baseURL: URL_PADRAO,
    });

    useEffect(() => {
        obterClinicasCadastradas();
        obterTenants();
        setPlanosDisponiveis(['ESSENCIAL', 'GESTAO']);
        console.log('clinicas', clinicas);
    }, []);

    function obterClinicasCadastradas() {
        api.get("/clinica", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((resposta) => {
            setClinicas(resposta.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.error("ops! ocorreu um erro" + err);
        });
    }

    function obterTenants() {
        api.get("/tenant", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((resposta) => {
            setTenantsDisponiveis(resposta.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.error("ops! ocorreu um erro" + err);
        });
    }

    const cadastrarUsuario = (event) => {
        setLoading(true);
        event.preventDefault();
        setExibeModalDeUsuarios(false);

        api.post("/clinica/usuario", {
            "login": login,
            "nome": nomeDoUsuario,
            "clinicaId": idDaClinicaSelecionada
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((resposta) => {
            setLoading(false);
            setExibeMensagemDeSucesso(true);
            setMensagemDeSucesso('Usuário cadastrado com sucesso!');
            obterClinicasCadastradas();
            limparCamposDeUsuario();
        }).catch((erro) => {
            setMensagemDeErro(erro.response.data);
            setLoading(false);
            console.error(erro);
        });

    }

    function limparCamposDeUsuario() {
        setLogin('');
        setNomeDoUsuario('');
        setIdDaClinicaSelecionada('');
    }

    const cadastrarClinica = (event) => {
        setLoading(true);
        event.preventDefault();

        api.post("/clinica", {
            "nome": nomeDaClinica,
            "tenantId": tenantSelecionado,
            "plano": planoSelecionado
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((resposta) => {
            setLoading(false);
            setExibeMensagemDeSucesso(true);
            setMensagemDeSucesso('Clínica cadastrada com sucesso!');
            obterClinicasCadastradas();
            limparCampos();
        }).catch((erro) => {
            setMensagemDeErro(erro.response.data);
            setLoading(false);
            console.error(erro);
        });
    }

    function limparCampos() {
        setNomeDaClinica('');
        setTenantSelecionado('');
        setPlanoSelecionado('');
    }

    function abrirUsuariosDaClinica(clinica) {
        setIdDaClinicaSelecionada(clinica.clinicaId);
        setUsuariosDaClinica(clinica.usuarios);
        setExibeModalDeUsuarios(true);
    }

    function abrirClinica(clinica) {
        setIdDaClinicaSelecionada(clinica.clinicaId);
        setNomeDaClinica(clinica.nome);
        setTenantSelecionado(clinica.tenantId);
        setPlanoSelecionado(clinica.plano);
    }

    function alterarDados() {
        setLoading(true);

        api.put("/clinica", {
            "clinicaId": idDaClinicaSelecionada,
            "nome": nomeDaClinica,
            "tenantId": tenantSelecionado,
            "plano": planoSelecionado
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((resposta) => {
            setLoading(false);
            setExibeMensagemDeSucesso(true);
            setMensagemDeSucesso('Clínica alterada com sucesso!');
            obterClinicasCadastradas();
        }).catch((erro) => {
            setMensagemDeErro(erro.response.data);
            setLoading(false);
            console.error(erro);
        });
    }

    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content" hidden={isLoading}>
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            {exibeMensagemDeSucesso &&
                                <MensagemDeSucesso mensagem={mensagemDeSucesso}/>}
                            {exibeMensagemDeErro &&
                                <MensagemDeErro mensagem={mensagemDeErro}/>}
                            <div className="row justify-content-between align-items-center">
                                <div className="col">
                                    <div className="d-flex">
                                        <h5>Cadastro de Clínicas</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light">
                            <div className="card-body border-top p-0">
                                <div className="row g-0 align-items-center border-bottom py-2 px-3">
                                    <div className="table-responsive scrollbar">
                                        <form className="row g-3 card-body needs-validation" onSubmit={cadastrarClinica}
                                              noValidate>
                                            <div className="col-md-6">
                                                <label className="form-label">Nome da clinica</label>
                                                <input className="form-control" type="text"
                                                       value={nomeDaClinica}
                                                       onChange={event => setNomeDaClinica(event.target.value)}
                                                       required/>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label">Tenant Id</label>
                                                <select value={tenantSelecionado}
                                                        className="form-select js-choice"
                                                        name="organizerSingle"
                                                        data-options='{"removeItemButton":true, "placeholder":true}'
                                                        onChange={event => setTenantSelecionado(event.target.value)}>
                                                    <option value="padrao">Selecione o Tenant</option>
                                                    {tenantsDisponiveis.map(tenant => (
                                                        <option key={tenant} value={tenant}>{tenant}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label">Plano</label>
                                                <select value={planoSelecionado}
                                                        className="form-select js-choice"
                                                        name="organizerSingle"
                                                        data-options='{"removeItemButton":true, "placeholder":true}'
                                                        onChange={event => setPlanoSelecionado(event.target.value)}>
                                                    <option value="padrao">Selecione o Plano</option>
                                                    {planosDisponiveis.map(plano => (
                                                        <option key={plano} value={plano}>{plano}</option>
                                                    ))}
                                                </select></div>

                                            <div className="col-12">
                                                <button className="btn btn-primary" type="submit" hidden={idDaClinicaSelecionada > 0}>Cadastrar</button>
                                            </div>
                                            <div className="col-12" hidden={idDaClinicaSelecionada === 0}>
                                                <a className="btn btn-primary"
                                                    onClick={() => alterarDados()}
                                                >Alterar</a>
                                            </div>
                                        </form>
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th scope="col">Nome da Clínica</th>
                                                <th scope="col">Plano</th>
                                                <th scope="col">Tenant</th>
                                                <th scope="col"></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {clinicas.map((clinica) => {
                                                    return <tr key={clinica.clinicaId}>
                                                        <td>{clinica.nome}</td>
                                                        <td>{clinica.plano}</td>
                                                        <td>{clinica.tenantId}</td>
                                                        <td className="text-end">
                                                            <div>
                                                                <a className="btn btn-link" title="Abrir Clínica"
                                                                   onClick={() => abrirClinica(clinica)}>
                                                                    <span className="fas fa-eye"></span>
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td className="text-end">
                                                            <div>
                                                                <a className="btn btn-link" title="Visualizar Usuários"
                                                                   onClick={() => abrirUsuariosDaClinica(clinica)}>
                                                                    <span className="fas fa-user-cog"></span>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal show={exibeModalDeUsuarios} centered
                               onHide={() => setExibeModalDeUsuarios(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Usuários</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form className="row g-3 card-body" onSubmit={cadastrarUsuario}>
                                    <div className="col-md-4">
                                        <label className="form-label">Usuário</label>
                                        <input className="form-control" type="text" value={nomeDoUsuario}
                                               onChange={event => setNomeDoUsuario(event.target.value)}/>
                                    </div>
                                    <span></span>
                                    <div className="col-md-8">
                                        <label className="form-label">Login</label>
                                        <input className="form-control" type="text" value={login}
                                               onChange={event => setLogin(event.target.value)}/>
                                    </div>
                                    <span></span>
                                    <div className="col-12">
                                        <button className="btn btn-primary" type="submit">Adicionar Usuário</button>
                                    </div>
                                </form>
                                <div className="text-center" hidden={usuariosDaClinica.length > 0}>
                                    <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">
                                        Nenhum usuário cadastrado ainda.
                                    </p>
                                </div>
                                <table className="table" hidden={usuariosDaClinica.length === 0}>
                                    <thead>
                                    <tr>
                                        <th scope="col">Usuário</th>
                                        <th scope="col">Login</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {usuariosDaClinica.map((usuario) => {
                                            return <tr key={usuario.id}>
                                                <td>{usuario.nome}</td>
                                                <td>{usuario.login}</td>
                                            </tr>
                                        }
                                    )}
                                    </tbody>
                                </table>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </main>
    );

}