import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {FormCheck} from "react-bootstrap";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import Usuariologado from "../../componentes/layoutBase/usuariologado";
import {useLocation, useNavigate} from "react-router-dom";
import Loading from "../../componentes/loading";
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import MensagemDeErro from "../../componentes/mensagemDeErro";

export default function AnamneseDoPaciente() {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [exibeMensagemDeSucesso, setExibeMensagemDeSucesso] = useState(false);
    const [mensagemDeSucesso, setMensagemDeSucesso] = useState('');
    const [exibeMensagemDeErro, setExibeMensagemDeErro] = useState(false);
    const [mensagemDeErro, setMensagemDeErro] = useState('');
    const [nomeDoPaciente] = useState(location.state.nomeDoPaciente);
    const [telefoneDoPaciente] = useState(location.state.telefoneDoPaciente);
    const [pacienteId] = useState(location.state.pacienteId);

    const inputFocus = useRef(null);

    const [perguntasComResposta, setPerguntasComResposta] = useState([]);
    const [queixaPrincipal, setQueixaPrincipal] = useState('');
    const [anamneseId, setAnamneseId] = useState(0);

    function exibirMensagemDeErro() {
        setExibeMensagemDeSucesso(false);
        setExibeMensagemDeErro(true);
    }

    function exibirMensagemDeSucesso() {
        setExibeMensagemDeSucesso(true);
        setExibeMensagemDeErro(false);
    }

    useEffect(() => {
        obterAnamneseDoPaciente(pacienteId);
    }, []);

    function obterAnamneseDoPaciente(pacienteId) {
        api.get("/anamnese/paciente/".concat(pacienteId), {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                if (resposta.data) {
                    setAnamneseId(resposta.data.id);
                    setQueixaPrincipal(resposta.data.queixaPrincipal);
                    resposta.data.perguntasComRespostasDaAnamnese.map((pergunta) => {
                        if (!pergunta.observacao) {
                            pergunta.observacao = '';
                        }
                    });
                    setPerguntasComResposta(resposta.data.perguntasComRespostasDaAnamnese);
                } else {
                    obterPerguntasComRespostas();
                }
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }

    function obterPerguntasComRespostas() {
        api.get("/anamnese/perguntas", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setPerguntasComResposta(resposta.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }

    const adicionarAnamnese = (event) => {
        event.preventDefault();
        setLoading(true);
        const anamnese = {
            "queixaPrincipal": queixaPrincipal,
            "perguntasComRespostasDaAnamnese": perguntasComResposta,
            "pacienteId": pacienteId
        }
        api.post("/anamnese", anamnese, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                exibirMensagemDeSucesso();
                setMensagemDeSucesso('Anamnese cadastrada com sucesso!');
                inputFocus.current.focus();
            })
            .catch((erro) => {
                setLoading(false);
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                console.error(erro);
            });
    }

    const alterarAnamnese = (event) => {
        event.preventDefault();
        setLoading(true);
        const anamnese = {
            "queixaPrincipal": queixaPrincipal,
            "perguntasComRespostasDaAnamnese": perguntasComResposta,
            "pacienteId": pacienteId,
            "id": anamneseId
        }
        api.put("/anamnese", anamnese, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                exibirMensagemDeSucesso();
                setMensagemDeSucesso('Anamnese alterada com sucesso!');
                inputFocus.current.focus();
            })
            .catch((erro) => {
                setLoading(false);
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                console.error(erro);
            });
    }

    function marcarRespostaComoNao(perguntaClicada) {
        const perguntasAtualizadas = [];
        perguntasComResposta.map((pergunta) => {
            if (pergunta === perguntaClicada) {
                pergunta.respostaSimOuNao = false;
            }
            perguntasAtualizadas.push(pergunta);
        });
        setPerguntasComResposta(perguntasAtualizadas);
    }

    function marcarRespostaComoSim(perguntaClicada) {
        const perguntasAtualizadas = [];
        perguntasComResposta.map((pergunta) => {
            if (pergunta === perguntaClicada) {
                pergunta.respostaSimOuNao = true;
            }
            perguntasAtualizadas.push(pergunta);
        });
        setPerguntasComResposta(perguntasAtualizadas);
    }

    function irParaDadosGerais() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/prontuario", {state: dadosDoPaciente});
    }
    function irParaTratamentos() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/tratamento", {state: dadosDoPaciente});
    }

    function irParaDocumentos() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/documentos", {state: dadosDoPaciente});
    }

    function irParaEvolucoesGerais() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/evolucoesgerais", {state: dadosDoPaciente});
    }

    function atualizarObservacao(value, perguntaComResposta) {
        const listaDePerguntasAtualizadas = [];
        perguntasComResposta.map((pergunta) => {
           if(pergunta.id === perguntaComResposta.id){
               pergunta.observacao = value;
               listaDePerguntasAtualizadas.push(pergunta);
           } else {
               listaDePerguntasAtualizadas.push(pergunta);
           }
        });
        setPerguntasComResposta(listaDePerguntasAtualizadas);
    }
    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content">
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="row justify-content-between align-items-center">
                                <div className="col col-lg-8 mb-4">
                                    <h5 className="mb-0">Prontuário do Paciente</h5>
                                </div>
                                <div className="d-flex">
                                    <div className="avatar avatar-xl">
                                        <img className="rounded-circle" src="/images/thumb.png" alt=""/>
                                    </div>
                                    <div className="flex-1 fs--1">
                                        <h5 className="fs-0">{nomeDoPaciente}</h5>
                                        <p className="mb-0">{telefoneDoPaciente}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light">
                            <div className="col-12 col-sm-auto ms-auto">
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaDadosGerais()}>Dados Gerais
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaTratamentos()}>Tratamentos
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        disabled={true}>Anamnese
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaDocumentos()}>Documentos
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaEvolucoesGerais()}>Evoluções Gerais
                                </button>
                            </div>
                        </div>
                        {exibeMensagemDeSucesso &&
                            <MensagemDeSucesso mensagem={mensagemDeSucesso}/>}
                        {exibeMensagemDeErro &&
                            <MensagemDeErro mensagem={mensagemDeErro}/>}
                        <form className="row g-3 card-body needs-validation" onSubmit={adicionarAnamnese}>
                            <div className="col-md-12">
                                <div className="form-floating mb-3">
                                    <textarea rows="3" className="form-control" id="queixaPrincipal" style={{height:94}}
                                           placeholder="Queixa Principal"
                                           ref={inputFocus}
                                           value={queixaPrincipal}
                                           onChange={event => setQueixaPrincipal(event.target.value)}/>
                                    <label htmlFor="queixaPrincipal">Queixa Principal</label>
                                </div>
                            </div>
                            {perguntasComResposta.map((perguntaComResposta) => {
                                return <div key={perguntaComResposta.id} className="card col-md-10">
                                    <div className="card-body">
                                        <span>{perguntaComResposta.pergunta} </span>
                                        <FormCheck inline type="radio" name={perguntaComResposta.id} label="Sim"
                                                   checked={perguntaComResposta.respostaSimOuNao}
                                                   onChange={() => marcarRespostaComoSim(perguntaComResposta)}
                                                   disabled={perguntaComResposta.perguntaDeCampoAberto}/>
                                        <FormCheck inline type="radio" name={perguntaComResposta.id} label="Não"
                                                   checked={!perguntaComResposta.respostaSimOuNao}
                                                   onChange={() => marcarRespostaComoNao(perguntaComResposta)}
                                                   disabled={perguntaComResposta.perguntaDeCampoAberto}/>

                                        <div className="form-floating">
                                            <input type="text" className="form-control"
                                                   value={perguntaComResposta.observacao}
                                                   onChange={event => atualizarObservacao(event.target.value, perguntaComResposta)}/>
                                            <label>{perguntaComResposta.perguntaDeCampoAberto === true ? 'Descrição' : 'Observação'}</label>
                                        </div>
                                    </div>
                                </div>
                            })}
                            <div className="col-12" hidden={anamneseId > 0}>
                                <button className="btn btn-primary" type="submit">Salvar</button>
                            </div>
                            <div className="col-12" hidden={anamneseId === 0}>
                                <button className="btn btn-primary" onClick={alterarAnamnese}>Alterar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
}