export default function TodosStatusDeConsulta() {

    const styleParaStatusConfirmada = {
        backgroundColor: 'rgb(88 115 251)'
    };

    const styleParaStatusDesmarcada = {
        backgroundColor: 'rgb(139 23 147)'
    };

    const styleParaStatusReagendada = {
        backgroundColor: 'rgb(249 178 49)'
    };

    const styleParaStatusRealizada = {
        backgroundColor: '#b5fb91'
    };

    return (
        <div>
            <form className="row g-3 card-body">
                <div className="col-md-2">
                <span style={styleParaStatusConfirmada} className="badge badge rounded-pill d-block">
                    Confirmada<span className="ms-1 fas fa-check" /></span>
                </div>
                <div className="col-md-2">
                <span style={styleParaStatusRealizada} className="badge badge rounded-pill d-block badge-subtle-success">
                    Realizada<span className="ms-1 fas fa-check" /></span>
                </div>
                <div className="col-md-2">
                <span style={styleParaStatusDesmarcada} className="badge badge rounded-pill d-block">
                    Desmarcada<span className="ms-1 fas fa-ban" /></span>
                </div>
                <div className="col-md-2">
                <span style={styleParaStatusReagendada} className="badge badge rounded-pill d-block badge-subtle-success">
                    Reagendada<span className="ms-1 fas fa-redo" /></span>
                </div>
            </form>
        </div>
    );
}