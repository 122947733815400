import {useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Loading from "../../componentes/loading";

export default function Login() {

    const URL_PADRAO = require('../../api/url.json').api;
    const navigate = useNavigate();

    const formStyle = {
        maxWidth: "330px",
        padding: "15px"
    };

    const divStyle = {
        padding: "16rem",
        marginTop: "6rem"
    };

    const [isLoading, setLoading] = useState(false);
    const [emailOuSenhaIvalido, setEmailOuSenhaInvalido] = useState(false);
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');

    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleChangeSenha = (event) => {
        setSenha(event.target.value)
    }

    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        console.log('versao 07:56');

        const api = axios.create({
            baseURL: URL_PADRAO,
        });

        api.post("/login", {"login": email, "senha": senha})
            .then((resposta) => {
                sessionStorage.setItem('token', resposta.data.token);
                setLoading(false);
                navigate("/inicio");
            })
            .catch((erro) => {
                setEmailOuSenhaInvalido(true);
                setLoading(false);
                console.error(erro);
            });
    }

    return (
        <div className="container text-center">
            {isLoading && <Loading/> }
            <div id="conteudo-principal" className="position-relative" style={divStyle} hidden={isLoading}>
                <form className="position-absolute top-50 start-50 translate-middle"
                      style={formStyle} onSubmit={handleSubmit}>
                    <h3 className="d-flex justify-content-center"> SIGO </h3>
                    <p className="d-flex justify-content-center mb-5">Sistema de Gestão Odontológica</p>

                    <h1 className="h3 mt-3 fw-normal">Acesso ao sistema</h1>
                    <div className="form-floating">
                        <input type="email" className="form-control" id="floatingInput"
                               value={email} onChange={handleChangeEmail}/>
                        <label htmlFor="floatingInput">Email</label>
                    </div>
                    <div className="form-floating">
                        <input type="password" className="form-control" id="floatingPassword"
                               value={senha} onChange={handleChangeSenha}/>
                        <label htmlFor="floatingPassword">Senha</label>
                    </div>
                    {
                        emailOuSenhaIvalido &&
                        <div className="mt-3">
                            Email ou senha inválido.
                        </div>

                    }
                    <button className="mt-3 w-100 btn btn-lg btn-primary" type="submit">Entrar</button>
                </form>
            </div>
        </div>
    );
}