import Loading from "../../componentes/loading";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import Usuariologado from "../../componentes/layoutBase/usuariologado";
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import MensagemDeErro from "../../componentes/mensagemDeErro";
import axios from "axios";
import {useEffect, useState} from "react";


export default function CamposDoTratamentoParaImpressao() {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const [isLoading, setLoading] = useState(false);
    const [exibeMensagemDeSucesso, setExibeMensagemDeSucesso] = useState(false);
    const [mensagemDeSucesso, setMensagemDeSucesso] = useState('');
    const [exibeMensagemDeErro, setExibeMensagemDeErro] = useState(false);
    const [mensagemDeErro, setMensagemDeErro] = useState('');

    const [observacaoUm, setObservacaoUm] = useState('');
    const [tituloUm, setTituloUm] = useState('');
    const [conteudoUm, setConteudoUm] = useState('');
    const [nomeDaClinica, setNomeDaClinica] = useState('');
    const [nomeDaCidade, setNomeDaCidade] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');

    useEffect(() => {
        obterCamposDeImpressaoDeTratamento();
    }, []);

    function obterCamposDeImpressaoDeTratamento() {
        setLoading(true);
        api.get("/impressao/campos-do-tratamento", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setObservacaoUm(resposta.data.observacaoUm);
                setTituloUm(resposta.data.tituloUm);
                setConteudoUm(resposta.data.conteudoUm);
                setNomeDaClinica(resposta.data.nomeDaClinica);
                setNomeDaCidade(resposta.data.nomeDaCidade);
                setEmail(resposta.data.email);
                setTelefone(resposta.data.telefone);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.error("ops! ocorreu um erro" + err);
            });

    }

    const salvarCamposParaImpressaoDeTratamento = (event) => {
        event.preventDefault();
        setLoading(true);
        const campos = {
            "observacaoUm": observacaoUm,
            "tituloUm": tituloUm,
            "conteudoUm": conteudoUm,
            "nomeDaClinica": nomeDaClinica,
            "nomeDaCidade": nomeDaCidade,
            "email": email,
            "telefone": telefone
        };

        api.put("/impressao/campos-do-tratamento", campos, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setExibeMensagemDeSucesso(true);
                setMensagemDeSucesso('Campos atualizados com sucesso!');
            })
            .catch((erro) => {
                setExibeMensagemDeErro(true);
                setMensagemDeErro(erro.response.data);
                setLoading(false);
                console.error(erro);
            });
    }

    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content" hidden={isLoading}>
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            {exibeMensagemDeSucesso &&
                                <MensagemDeSucesso mensagem={mensagemDeSucesso}/>}
                            {exibeMensagemDeErro &&
                                <MensagemDeErro mensagem={mensagemDeErro}/>}
                            <div className="row justify-content-between align-items-center">
                                <div className="col">
                                    <div className="d-flex">
                                        <h5 className="texto-negrito">Configurações dos campos para impressão de tratamento</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light">
                            <div className="card-body border-top p-0">
                                <div className="row g-0 align-items-center border-bottom py-2 px-3">
                                    <form className="row g-3 card-body needs-validation"
                                          noValidate onSubmit={salvarCamposParaImpressaoDeTratamento}>
                                        <div className="col-md-10">
                                            <label className="form-label">Observação Um</label>
                                            <textarea className="form-control" rows="3"
                                                      value={observacaoUm}
                                                      onChange={event => setObservacaoUm(event.target.value)}/>
                                        </div>
                                        <div className="col-md-10">
                                            <label className="form-label">Título Um</label>
                                            <textarea className="form-control" rows="3"
                                                      value={tituloUm}
                                                      onChange={event => setTituloUm(event.target.value)}/>
                                        </div>
                                        <div className="col-md-10">
                                            <label className="form-label">Conteúdo Um</label>
                                            <textarea className="form-control" rows="3"
                                                      value={conteudoUm}
                                                      onChange={event => setConteudoUm(event.target.value)}/>
                                        </div>
                                        <div className="col-md-10">
                                            <label className="form-label">Nome da Clínica</label>
                                            <textarea className="form-control" rows="3"
                                                      value={nomeDaClinica}
                                                      onChange={event => setNomeDaClinica(event.target.value)}/>
                                        </div>
                                        <div className="col-md-10">
                                            <label className="form-label">Cidade</label>
                                            <input className="form-control" type="text"
                                                   value={nomeDaCidade}
                                                   onChange={event => setNomeDaCidade(event.target.value)}/>
                                        </div>
                                        <div className="col-md-10">
                                            <label className="form-label">Email</label>
                                            <input className="form-control" type="text"
                                                   value={email}
                                                   onChange={event => setEmail(event.target.value)}/>
                                        </div>
                                        <div className="col-md-10">
                                            <label className="form-label">Telefone</label>
                                            <input className="form-control" type="text"
                                                   value={telefone}
                                                   onChange={event => setTelefone(event.target.value)}/>
                                        </div>

                                        <div className="col-12">
                                            <button className="btn btn-primary" type="submit">Salvar Alterações</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}