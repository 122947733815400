import {useEffect, useState} from "react";
import axios from "axios";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import Usuariologado from "../../componentes/layoutBase/usuariologado";
import {useLocation, useNavigate} from "react-router-dom";
import Loading from "../../componentes/loading";
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import MensagemDeErro from "../../componentes/mensagemDeErro";
import {Modal} from "react-bootstrap";
import DatePicker from "react-date-picker";

export default function EvolucoesGeraisDoPaciente() {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [exibeMensagemDeSucesso, setExibeMensagemDeSucesso] = useState(false);
    const [mensagemDeSucesso, setMensagemDeSucesso] = useState('');
    const [exibeMensagemDeErro, setExibeMensagemDeErro] = useState(false);
    const [mensagemDeErro, setMensagemDeErro] = useState('');
    const [nomeDoPaciente] = useState(location.state.nomeDoPaciente);
    const [telefoneDoPaciente] = useState(location.state.telefoneDoPaciente);
    const [pacienteId] = useState(location.state.pacienteId);

    const [evolucoesDoPaciente, setEvolucoesDoPaciente] = useState([]);
    const [exibeModalDeAdicionarEvolucao, setExibeModalDeAdicionarEvolucao] = useState(false);
    const [dataDaEvolucao, setDataDaEvolucao] = useState('');
    const [descricaoDaEvolucao, setDescricaoDaEvolucao] = useState('');

    function exibirMensagemDeErro() {
        setExibeMensagemDeSucesso(false);
        setExibeMensagemDeErro(true);
    }

    function exibirMensagemDeSucesso() {
        setExibeMensagemDeSucesso(true);
        setExibeMensagemDeErro(false);
    }

    useEffect(() => {
        obterEvolucoesGeraisDoPaciente();
    }, []);

    function obterEvolucoesGeraisDoPaciente() {
        api.get("/paciente/evolucao/".concat(pacienteId), {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setEvolucoesDoPaciente(resposta.data);
            })
            .catch((erro) => {
                console.error(erro);
            });
    }

    function irParaDadosGerais() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/prontuario", {state: dadosDoPaciente});
    }

    function irParaTratamentos() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/tratamento", {state: dadosDoPaciente});
    }

    function irParaAnamnese() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/anamnese", {state: dadosDoPaciente});
    }

    function irParaDocumentos() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/documentos", {state: dadosDoPaciente});
    }

    function abrirModalDeAdicionarEvolucao() {
        setExibeModalDeAdicionarEvolucao(true);
    }

    const adicionarEvolucao = (event) => {
        event.preventDefault();
        setLoading(true);
        setExibeModalDeAdicionarEvolucao(false);
        const evolucao = {
            "data": dataDaEvolucao,
            "evolucao": descricaoDaEvolucao,
            "pacienteId": pacienteId
        };
        api.post("/paciente/evolucao", evolucao, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                exibirMensagemDeSucesso();
                setMensagemDeSucesso('Evolução cadastrado com sucesso!');
                limparCamposDaEvolucao();
                obterEvolucoesGeraisDoPaciente();
            })
            .catch((erro) => {
                setLoading(false);
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                console.error(erro);
            });
    }

    function limparCamposDaEvolucao() {
        setDataDaEvolucao('');
        setDescricaoDaEvolucao('');
    }

    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content">
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="row justify-content-between align-items-center">
                                <div className="col col-lg-8 mb-4">
                                    <h5 className="mb-0">Prontuário do Paciente</h5>
                                </div>
                                <div className="d-flex">
                                    <div className="avatar avatar-xl">
                                        <img className="rounded-circle" src="/images/thumb.png" alt=""/>
                                    </div>
                                    <div className="flex-1 fs--1">
                                        <h5 className="fs-0">{nomeDoPaciente}</h5>
                                        <p className="mb-0">{telefoneDoPaciente}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light">
                            <div className="col-12 col-sm-auto ms-auto">
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaDadosGerais()}>Dados Gerais
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaTratamentos()}>Tratamentos
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaAnamnese()}>Anamnese
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaDocumentos()}>Documentos
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        disabled={true}>Evoluções Gerais
                                </button>
                            </div>
                        </div>
                        {exibeMensagemDeSucesso &&
                            <MensagemDeSucesso mensagem={mensagemDeSucesso}/>}
                        {exibeMensagemDeErro &&
                            <MensagemDeErro mensagem={mensagemDeErro}/>}
                        <div id="evolucoes-gerais-div" className="card mb-3 d-print-none">
                            <div className="card-header">
                                <div className="row flex-between-center">
                                    <div className="col col-lg-8">
                                        <h5 className="mb-0">Evoluções Gerais</h5>
                                    </div>
                                    <div className="col-12 col-sm-auto ms-auto">
                                        <a className="btn btn-falcon-primary" type="button"
                                           onClick={() => abrirModalDeAdicionarEvolucao()}>
                                            Adicionar Nova Evolução
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center" hidden={evolucoesDoPaciente.length > 0}>
                                <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">
                                    Nenhuma Evolução cadastrada ainda.
                                </p>
                            </div>

                            <div className="card-body ps-2">
                                {evolucoesDoPaciente.map((evolucao) => {
                                        return <div key={evolucao.descricao}>
                                            <div className="row g-3 timeline timeline-primary timeline-past pb-x1">
                                                <div className="col-auto ps-4 ms-2">
                                                    <div className="ps-2">
                                                        <div className="icon-item icon-item-sm rounded-circle bg-200 shadow-none">
                                                            <span className="text-primary fas fa-file-alt"></span></div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="row gx-0 border-bottom pb-x1">
                                                        <div className="col">
                                                            <h6 className="text-800 mb-1">{evolucao.data}</h6>
                                                            <p className="fs--1 text-600 mb-0">{evolucao.evolucao}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                )}
                            </div>
                        </div>
                        <Modal show={exibeModalDeAdicionarEvolucao} centered size={"lg"}
                               onHide={() => setExibeModalDeAdicionarEvolucao(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Evoluções Gerais</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form className="row g-3 card-body needs-validation" onSubmit={adicionarEvolucao}>
                                    <div className="col-md-4">
                                        <label className="form-label">Data</label>
                                        <DatePicker value={dataDaEvolucao} className="form-control"
                                                    onChange={(date) => setDataDaEvolucao(date)}
                                                    format="dd/MM/yyyy"></DatePicker>
                                    </div>
                                    <div className="mb-3">
                                        <label className="col-form-label">Descrição:</label>
                                        <textarea className="form-control" type="text"
                                                  value={descricaoDaEvolucao}
                                                  onChange={event => setDescricaoDaEvolucao(event.target.value)}/>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary" type="submit">Adicionar Evolução</button>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </main>
    );
}