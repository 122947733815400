import {useEffect, useState} from "react";
import axios from "axios";

export default function Cidade(props) {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const [cidades, setCidades] = useState([]);

    useEffect(() => {
        obterCidades();
    }, []);

    function obterCidades() {
        api.get("/cidade", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setCidades(resposta.data);
            })
            .catch((erro) => {
                console.error(erro);
            });
    }

    return (
        <select className="form-select" required
                value={props.cidadeSelecionada} onChange={props.selecionarCidade}>
            <option value="0">Selecione</option>
            {cidades.map((cidade) => {
                    return <option key={cidade.value} value={cidade.value}>{cidade.nome}</option>
                }
            )}
        </select>
    );
}