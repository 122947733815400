
export default function MensagemDeErro(props){

    return (
        <div className="alert alert-danger border-2 d-flex align-items-center" role="alert">
            <div className="bg-danger me-3 icon-item">
                <span className="fas fa-exclamation-circle text-white fs-3"></span>
            </div>
            <p className="mb-0 flex-1">{props.mensagem}</p>
        </div>
    );
}