import DatePicker from "react-date-picker";
import InputMask from "react-input-mask";
import axios from "axios";
import {useEffect, useRef, useState} from "react";
import moment from "moment";
import Cidade from "../../componentes/cidade";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import Usuariologado from "../../componentes/layoutBase/usuariologado";
import {useLocation, useNavigate} from "react-router-dom";
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import MensagemDeErro from "../../componentes/mensagemDeErro";
import Loading from "../../componentes/loading";

export default function DadosGeraisDoPaciente() {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const [escondeFormulario, setEscondeFormulario] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const [nomeDoPaciente, setNomeDoPaciente] = useState();
    const [telefoneDoPaciente, setTelefoneDoPaciente] = useState();
    const [pacienteId] = useState(location.state.pacienteId);
    const [exibeMensagemDeSucesso, setExibeMensagemDeSucesso] = useState(false);
    const [mensagemDeSucesso, setMensagemDeSucesso] = useState('');
    const [exibeMensagemDeErro, setExibeMensagemDeErro] = useState(false);
    const [mensagemDeErro, setMensagemDeErro] = useState('');
    const [isLoading, setLoading] = useState(false);

    const inputFocus = useRef(null);

    const [nome, setNome] = useState('');
    const [dataDeNascimento, setDataDeNascimento] = useState('');
    const [sexo, setSexo] = useState('');
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [telefone, setTelefone] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidadeSelecionada, setCidadeSelecionada] = useState('');
    const [complemento, setComplemento] = useState('');
    const [indicadoPor, setIndicadoPor] = useState('');
    const [cirurgiaoAnterior, setCirurgiaoAnterior] = useState('');
    const [contatoDeEmergencia, setContatoDeEmergencia] = useState('');
    const [creditoPrimeiraConsulta, setCreditoPrimeiraConsulta] = useState(0);
    const [nomeDoResponsavel, setNomeDoResponsavel] = useState('');
    const [dataDeNascimentoDoResponsavel, setDataDeNascimentoDoResponsavel] = useState('');
    const [cpfDoResponsavel, setCpfDoResponsavel] = useState('');
    const [telefoneDoResponsavel, setTelefoneDoResponsavel] = useState('');
    const [parentescoDoResponsavel, setParentescoDoResponsavel] = useState('');

    useEffect(() => {
        obterPaciente(pacienteId);
    }, []);

    function exibirMensagemDeErro() {
        setExibeMensagemDeSucesso(false);
        setExibeMensagemDeErro(true);
    }

    function exibirMensagemDeSucesso() {
        setExibeMensagemDeSucesso(true);
        setExibeMensagemDeErro(false);
    }

    function obterPaciente(pacienteId) {
        setLoading(true);
        api.get("/paciente/".concat(pacienteId), {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                const paciente = resposta.data;
                setNomeDoPaciente(paciente.nome);
                setTelefoneDoPaciente(paciente.telefone);
                setNome(paciente.nome);
                if (paciente.dataDeNascimento != null) {
                    setDataDeNascimento(moment(paciente.dataDeNascimento, 'DD/MM/YYYY').toDate());
                }
                setSexo(paciente.sexo);
                setCpf(paciente.cpf);
                setRg(paciente.rg);
                setTelefone(paciente.telefone);
                setRua(paciente.rua);
                setNumero(paciente.numero);
                setBairro(paciente.bairro);
                setCidadeSelecionada(paciente.cidade);
                setComplemento(paciente.complementoDeEndereco);
                setIndicadoPor(paciente.nomeDeQuemIndicou);
                setCirurgiaoAnterior(paciente.nomeDoCirurgiaoDentistaAnterior);
                setCreditoPrimeiraConsulta(paciente.creditoDaPrimeiraConsulta);
                setNomeDoResponsavel(paciente.nomeDoResponsavel);
                setDataDeNascimentoDoResponsavel(paciente.dataDeNascimentoDoResponsavel ? moment(paciente.dataDeNascimentoDoResponsavel, 'DD/MM/YYYY').toDate() : '');
                setCpfDoResponsavel(paciente.cpfDoResponsavel);
                setTelefoneDoResponsavel(paciente.telefoneDoResponsavel);
                setParentescoDoResponsavel(paciente.parentescoDoResponsavel);
                setEscondeFormulario(false);
                setLoading(false);
            })
            .catch((erro) => {
                setLoading(false);
                console.error(erro);
            });
    }

    const selecionarCidade = (event) => {
        setCidadeSelecionada(event.target.value);
    }

    const alterarPaciente = (event) => {
        event.preventDefault();
        setLoading(true);
        const paciente = {
            "id": pacienteId,
            "nome": nome,
            "cpf": cpf,
            "rg": rg,
            "sexo": sexo,
            "dataDeNascimento": dataDeNascimento,
            "telefone": telefone,
            "rua": rua,
            "numero": numero,
            "bairro": bairro,
            "cidade": cidadeSelecionada,
            "complementoDeEndereco": complemento,
            "nomeDoResponsavel": nomeDoResponsavel,
            "dataDeNascimentoDoResponsavel": dataDeNascimentoDoResponsavel,
            "cpfDoResponsavel": cpfDoResponsavel,
            "telefoneDoResponsavel": telefoneDoResponsavel,
            "parentescoDoResponsavel": parentescoDoResponsavel,
            "nomeDeQuemIndicou": indicadoPor,
            "contatoDeEmergencia": contatoDeEmergencia,
            "nomeDoCirurgiaoDentistaAnterior": cirurgiaoAnterior,
            "creditoDaPrimeiraConsulta": creditoPrimeiraConsulta
        };

        api.put("/paciente", paciente, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                exibirMensagemDeSucesso();
                setMensagemDeSucesso('Paciente alterado com sucesso!');
                setNomeDoPaciente(nome);
                setTelefoneDoPaciente(telefone);
                setLoading(false);
                inputFocus.current.focus();
            })
            .catch((erro) => {
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                setLoading(false);
                console.error(erro);
            });
    }

    function irParaTratamentos() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/tratamento", {state: dadosDoPaciente});
    }

    function irParaAnamnese() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/anamnese", {state: dadosDoPaciente});
    }

    function irParaDocumentos() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/documentos", {state: dadosDoPaciente});
    }

    function irParaEvolucoesGerais() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/evolucoesgerais", {state: dadosDoPaciente});
    }

    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content">
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="row justify-content-between align-items-center">
                                <div className="col col-lg-8 mb-4">
                                    <h5 className="mb-0">Prontuário do Paciente</h5>
                                </div>
                                <div className="d-flex">
                                    <div className="avatar avatar-xl">
                                        <img className="rounded-circle" src="/images/thumb.png" alt=""/>
                                    </div>
                                    <div className="flex-1 fs--1">
                                        <h5 className="fs-0">{nomeDoPaciente}</h5>
                                        <p className="mb-0">{telefoneDoPaciente}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light">
                            <div className="col-12 col-sm-auto ms-auto">
                                <button className="btn btn-falcon-primary m-md-1" type="button" disabled={true}>Dados
                                    Gerais
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaTratamentos()}>Tratamentos
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaAnamnese()}>Anamnese
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaDocumentos()}>Documentos
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaEvolucoesGerais()}>Evoluções Gerais
                                </button>
                            </div>
                        </div>
                        {exibeMensagemDeSucesso &&
                            <MensagemDeSucesso mensagem={mensagemDeSucesso}/>}
                        {exibeMensagemDeErro &&
                            <MensagemDeErro mensagem={mensagemDeErro}/>}
                        <form className="row g-3 card-body needs-validation" onSubmit={alterarPaciente}
                              hidden={escondeFormulario}
                              noValidate>
                            <div className="col-md-4">
                                <label className="form-label">Nome</label>
                                <input id="nome" className="form-control" type="text" value={nome}
                                       ref={inputFocus}
                                       onChange={event => setNome(event.target.value)} required/>
                                <div className="invalid-feedback">
                                    é obrigatório informar o nome.
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label" htmlFor="dataDeNascimento">Data de Nascimento</label>
                                <DatePicker value={dataDeNascimento} className="form-control"
                                            onChange={(date) => setDataDeNascimento(date)}
                                            format="dd/MM/yyyy"></DatePicker>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Sexo</label>
                                <select className="form-select"
                                        value={sexo} onChange={event => setSexo(event.target.value)}
                                        required>
                                    <option value="0">Selecione</option>
                                    <option value="MASCULINO">Masculino</option>
                                    <option value="FEMININO">Feminino</option>
                                </select>
                                <div className="invalid-feedback">
                                    é obrigatório informar o sexo.
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Cpf</label>
                                <InputMask className="form-control" mask="999.999.999-99"
                                           value={cpf} onChange={event => setCpf(event.target.value)}>

                                </InputMask>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Rg</label>
                                <input className="form-control" type="number"
                                       value={rg} onChange={event => setRg(event.target.value)}/>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Telefone</label>
                                <InputMask className="form-control" mask="(99)99999-9999"
                                           value={telefone}
                                           onChange={event => setTelefone(event.target.value)}></InputMask>
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Rua</label>
                                <input className="form-control" type="text"
                                       value={rua} onChange={event => setRua(event.target.value)}/>
                            </div>
                            <div className="col-md-2">
                                <label className="form-label">Número</label>
                                <input className="form-control" type="text"
                                       value={numero} onChange={event => setNumero(event.target.value)}/>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Bairro</label>
                                <input className="form-control" type="text"
                                       value={bairro} onChange={event => setBairro(event.target.value)}/>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Cidade</label>
                                <Cidade cidadeSelecionada={cidadeSelecionada} selecionarCidade={selecionarCidade}/>
                                <div className="invalid-feedback">
                                    é obrigatório informar a cidade.
                                </div>
                            </div>
                            <div className="col-md-7">
                                <label className="form-label">Complemento</label>
                                <input className="form-control" type="text"
                                       value={complemento} onChange={event => setComplemento(event.target.value)}/>
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Indicado por</label>
                                <input className="form-control" type="text"
                                       value={indicadoPor} onChange={event => setIndicadoPor(event.target.value)}/>
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Cirurgião Dentista Anterior</label>
                                <input className="form-control" type="text"
                                       value={cirurgiaoAnterior}
                                       onChange={event => setCirurgiaoAnterior(event.target.value)}/>
                            </div>
                            <div className="mb-3 mt-3">
                                <label className="form-label">Em caso de emergência contactar</label>
                                <textarea className="form-control" id="contatoParaEmergencia" rows="2"
                                          value={contatoDeEmergencia}
                                          onChange={event => setContatoDeEmergencia(event.target.value)}/>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Crédito da primeira consulta</label>
                                <div className="input-group">
                                    <span className="input-group-text">R$</span>
                                    <input className="form-control" id="currency" type="number"
                                           value={creditoPrimeiraConsulta}
                                           onChange={event => setCreditoPrimeiraConsulta(event.target.value)}/>
                                </div>
                            </div>

                            <h4>Dados do responsável</h4>

                            <div className="col-md-6">
                                <label className="form-label">Nome</label>
                                <input className="form-control" type="text"
                                       value={nomeDoResponsavel}
                                       onChange={event => setNomeDoResponsavel(event.target.value)}/>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Data de
                                    Nascimento</label>
                                <DatePicker value={dataDeNascimentoDoResponsavel} className="form-control"
                                            onChange={(date) => setDataDeNascimentoDoResponsavel(date)}
                                            format="dd/MM/yyyy"></DatePicker>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Cpf</label>
                                <InputMask className="form-control" mask="999.999.999-99"
                                           value={cpfDoResponsavel}
                                           onChange={event => setCpfDoResponsavel(event.target.value)}></InputMask>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Telefone</label>
                                <InputMask className="form-control" mask="(99)99999-9999"
                                           value={telefoneDoResponsavel}
                                           onChange={event => setTelefoneDoResponsavel(event.target.value)}></InputMask>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Parentesco</label>
                                <input className="form-control" type="text"
                                       value={parentescoDoResponsavel}
                                       onChange={event => setParentescoDoResponsavel(event.target.value)}/>
                            </div>

                            <div className="col-12">
                                <button className="btn btn-primary" type="submit">Alterar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
}