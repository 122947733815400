import axios from "axios";
import {useEffect, useState} from "react";

export default function Dentista(props) {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const [dentistas, setDentistas] = useState([]);

    useEffect(() => {
        obterDentistas();
    }, []);

    function obterDentistas() {
        api.get("/dentista", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setDentistas(resposta.data);
            })
            .catch((erro) => {
                console.error(erro);
            });
    }

    return (
        <div>
            <label className="form-label">Dentista</label>
            <div className="tab-pane preview-tab-pane active" role="tabpanel">
                <select className="form-select" required
                        value={props.dentistaSelecionado}
                        onChange={props.selecionarDentista}
                        disabled={props.disable}>
                    <option value="0">Selecione</option>
                    {dentistas.map((dentista) => {
                            return <option key={dentista.id} value={dentista.id}>{dentista.nome}</option>
                        }
                    )}
                </select>
            </div>
            <div className="invalid-feedback">
                é obrigatório informar o dentista.
            </div>
        </div>
    );
}