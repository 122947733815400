import "../../css/spinner.css"

export default function Loading() {

    return (
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
    );
}