import Loading from "../../componentes/loading";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import Usuariologado from "../../componentes/layoutBase/usuariologado";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {Modal} from "react-bootstrap";
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import MensagemDeErro from "../../componentes/mensagemDeErro";
import ReactToPrint from "react-to-print";
import {TermoParaImpressao} from "../impressoes.termo";
import Dentista from "../../componentes/dentista";
import DatePicker from "react-date-picker";
import InputMask from "react-input-mask";
import {AtestadoParaImpressao} from "../impressoes.atestado";
import {ReceituarioParaImpressao} from "../impressoes.receituario";

export default function DocumentosDoPaciente() {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const location = useLocation();
    const navigate = useNavigate();
    const componentRefParaAtestado = useRef();
    const componentRefParaTermo = useRef();
    const componentRefParaReceituario = useRef();
    const [isLoading, setLoading] = useState(false);
    const [exibeMensagemDeSucesso, setExibeMensagemDeSucesso] = useState(false);
    const [mensagemDeSucesso, setMensagemDeSucesso] = useState('');
    const [exibeMensagemDeErro, setExibeMensagemDeErro] = useState(false);
    const [mensagemDeErro, setMensagemDeErro] = useState('');
    const [nomeDoPaciente] = useState(location.state.nomeDoPaciente);
    const [telefoneDoPaciente] = useState(location.state.telefoneDoPaciente);
    const [pacienteId] = useState(location.state.pacienteId);

    // ####### RECEITUARIOS
    const [receituarios, setReceituarios] = useState([]);
    const [exibeModalDeReceituarios, setExibeModalDeReceituarios] = useState(false);
    const [medicamentosParaAdicionar, setMedicamentosParaAdicionar] = useState([]);
    const [nomeDoMedicamento, setNomeDoMedicamento] = useState('');
    const [quantidadeDoMedicamento, setQuantidadeDoMedicamento] = useState('');
    const [posologiaDoMedicamento, setPosologiaDoMedicamento] = useState('');
    const [dataDoReceituario, setDataDoReceituario] = useState('');
    // #######

    // ####### ATESTADOS
    const [atestados, setAtestados] = useState([]);
    const [exibeModalDeAtestados, setExibeModalDeAtestados] = useState(false);
    const [idDoDentistaSelecionado, setIdDoDentistaSelecionado] = useState('');
    const [tipoDeAtestado, setTipoDeAtestado] = useState('');
    const [cid, setCid] = useState('');
    const [dataDoAtestado, setDataDoAtestado] = useState('');
    const [quantidadeDeDiasDeRepouso, setQuantidadeDeDiasDeRepouso] = useState('');
    const [horaInicial, setHoraInicial] = useState('');
    const [horaFinal, setHoraFinal] = useState('');
    // #######

    // ####### TERMO
    const [termosCadastrados, setTermosCadastrados] = useState([]);
    const [exibeModalDeTermos, setExibeModalDeTermos] = useState(false);
    const [editor, setEditor] = useState(EditorState.createEmpty());
    const [nomeDoTermo, setNomeDoTermo] = useState('');
    const [idDoTermo, setIdDoTermo] = useState(0);
    // #######


    useEffect(() => {
        obterReceituariosDoPaciente();
        obterTermosCadastrados();
        obterAtestadosDoPaciente();
    }, []);

    // ####### RECEITUARIOS

    function obterReceituariosDoPaciente() {
        api.get("/receituario/".concat(pacienteId), {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setReceituarios(resposta.data);
            })
            .catch((erro) => {
                setLoading(false);
                console.error(erro);
            });
    }

    function abrirModalDeReceituario() {
        setExibeModalDeReceituarios(true);
    }

    function adicionarMedicamentoNaLista() {
        let medicamento = {
            "nome": nomeDoMedicamento,
            "descricaoQuantidade": quantidadeDoMedicamento,
            "posologia": posologiaDoMedicamento
        };
        setMedicamentosParaAdicionar(medicamentosParaAdicionar => [...medicamentosParaAdicionar, medicamento]);
        limparCamposDoReceituario();
    }

    function removerMedicamentoDaLista(medicamento) {
        medicamentosParaAdicionar.map(() => {
            const indexMedicamento = medicamentosParaAdicionar.indexOf(medicamento);
            medicamentosParaAdicionar.splice(indexMedicamento, 1);
        });
        setMedicamentosParaAdicionar(medicamentosParaAdicionar => [...medicamentosParaAdicionar]);
    }

    function limparCamposDoReceituario() {
        setNomeDoMedicamento('');
        setQuantidadeDoMedicamento('');
        setPosologiaDoMedicamento('');
    }

    const salvarReceituario = (event) => {
        event.preventDefault();
        setLoading(true);
        setExibeModalDeReceituarios(false);
        const receituarioParaAdicionar = {
            "dentistaId": idDoDentistaSelecionado,
            "pacienteId": pacienteId,
            "data": dataDoReceituario,
            "medicamentos": medicamentosParaAdicionar
        };
        console.log('receituarioParaAdicionar', receituarioParaAdicionar);
        api.post("/receituario", receituarioParaAdicionar, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                exibirMensagemDeSucesso();
                limparCamposDoAtestado();
                setMensagemDeSucesso('Receituario cadastrado com sucesso!');
                obterReceituariosDoPaciente();
            })
            .catch((erro) => {
                setLoading(false);
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                console.error(erro);
            });
    }
    // ####### FIM RECEITUARIOS

    // ####### ATESTADOS

    function abrirModalDeAtestado() {
        setExibeModalDeAtestados(true);
    }

    const selecionarDentista = (event) => {
        setIdDoDentistaSelecionado(event.target.value);
    }

    function obterAtestadosDoPaciente() {
        api.get("/atestado/".concat(pacienteId), {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setAtestados(resposta.data);
            })
            .catch((erro) => {
                setLoading(false);
                console.error(erro);
            });
    }

    const cadastrarAtestado = (event) => {
        event.preventDefault();
        setLoading(true);
        const atestado = {
            "dentistaId": idDoDentistaSelecionado,
            "pacienteId": pacienteId,
            "tipoDeAtestado": tipoDeAtestado,
            "cid": cid,
            "quantidadeDeDiasDeRepouso": quantidadeDeDiasDeRepouso,
            "horaInicial": horaInicial,
            "horaFinal": horaFinal,
            "data": dataDoAtestado,
        };
        api.post("/atestado", atestado, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setExibeModalDeAtestados(false);
                exibirMensagemDeSucesso();
                limparCamposDoAtestado();
                setMensagemDeSucesso('Atestado cadastrado com sucesso!');
                obterAtestadosDoPaciente();
            })
            .catch((erro) => {
                setLoading(false);
                setExibeModalDeTermos(false);
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                console.error(erro);
            });
    }

    function limparCamposDoAtestado() {
        setIdDoDentistaSelecionado('');
        setTipoDeAtestado('');
        setCid('');
        setQuantidadeDeDiasDeRepouso('');
        setHoraInicial('');
        setHoraFinal('');
        setDataDoAtestado('');
    }

    // ####### ATESTADOS FIM

    // ####### TERMOS
    function obterTermosCadastrados() {
        setLoading(true);
        api.get("/termo", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setTermosCadastrados(resposta.data);
                setLoading(false);
            })
            .catch((erro) => {
                console.error(erro);
                setLoading(false);
            });

    }

    function abrirModalDeTermos() {
        limparCamposDoTermo();
        setExibeModalDeTermos(true);
    }

    function limparCamposDoTermo() {
        setNomeDoTermo('');
        setEditor(EditorState.createEmpty());
    }

    const adicionarTermo = (event) => {
        event.preventDefault();
        setLoading(true);
        const termo = {
            "nome": nomeDoTermo,
            "conteudo": draftToHtml(convertToRaw(editor.getCurrentContent()))
        };
        api.post("/termo", termo, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setExibeModalDeTermos(false);
                exibirMensagemDeSucesso();
                limparCamposDoTermo();
                setMensagemDeSucesso('Termo cadastrado com sucesso!');
                obterTermosCadastrados();
            })
            .catch((erro) => {
                setLoading(false);
                setExibeModalDeTermos(false);
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                console.error(erro);
            });
    }

    const alterarTermo = (event) => {
        event.preventDefault();
        setLoading(true);
        const termo = {
            "id": idDoTermo,
            "nome": nomeDoTermo,
            "conteudo": draftToHtml(convertToRaw(editor.getCurrentContent()))
        };
        api.put("/termo", termo, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setExibeModalDeTermos(false);
                exibirMensagemDeSucesso();
                limparCamposDoTermo();
                setMensagemDeSucesso('Termo alterado com sucesso!');
                obterTermosCadastrados();
            })
            .catch((erro) => {
                setLoading(false);
                setExibeModalDeTermos(false);
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                console.error(erro);
            });
    }

    function abrirTermo(termo) {
        setExibeModalDeTermos(true);
        setIdDoTermo(termo.id);
        setNomeDoTermo(termo.nome);
        const blocksFromHtml = htmlToDraft(termo.conteudo);
        const {contentBlocks, entityMap} = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        setEditor(editorState);
    }

    // ####### TERMOS FIM

    function irParaDadosGerais() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/prontuario", {state: dadosDoPaciente});
    }

    function irParaTratamentos() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/tratamento", {state: dadosDoPaciente});
    }

    function irParaAnamnese() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/anamnese", {state: dadosDoPaciente});
    }

    function irParaEvolucoesGerais() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/evolucoesgerais", {state: dadosDoPaciente});
    }

    function exibirMensagemDeErro() {
        setExibeMensagemDeSucesso(false);
        setExibeMensagemDeErro(true);
    }

    function exibirMensagemDeSucesso() {
        setExibeMensagemDeSucesso(true);
        setExibeMensagemDeErro(false);
    }

    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content">
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="row justify-content-between align-items-center">
                                <div className="col col-lg-8 mb-4">
                                    <h5 className="mb-0">Prontuário do Paciente</h5>
                                </div>
                                <div className="d-flex">
                                    <div className="avatar avatar-xl">
                                        <img className="rounded-circle" src="/images/thumb.png" alt=""/>
                                    </div>
                                    <div className="flex-1 fs--1">
                                        <h5 className="fs-0">{nomeDoPaciente}</h5>
                                        <p className="mb-0">{telefoneDoPaciente}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light">
                            <div className="col-12 col-sm-auto ms-auto">
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaDadosGerais()}>Dados Gerais
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaTratamentos()}>Tratamentos
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaAnamnese()}>Anamnese
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        disabled={true}>Documentos
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaEvolucoesGerais()}>Evoluções Gerais
                                </button>
                            </div>
                        </div>
                        {exibeMensagemDeSucesso &&
                            <MensagemDeSucesso mensagem={mensagemDeSucesso}/>}
                        {exibeMensagemDeErro &&
                            <MensagemDeErro mensagem={mensagemDeErro}/>}

                        <div id="receituario-div" className="card mb-3">
                            <div className="card-header">
                                <div className="row flex-between-center">
                                    <div className="col col-lg-8">
                                        <h5 className="mb-0">Receituários</h5>
                                    </div>
                                    <div className="col-12 col-sm-auto ms-auto">
                                        <a className="btn btn-falcon-primary" type="button"
                                           onClick={() => abrirModalDeReceituario()}>
                                            Adicionar Novo
                                        </a>
                                    </div>
                                </div>
                                <div className="text-center" hidden={receituarios.length > 0}>
                                    <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">
                                        Nenhum receituário cadastrado ainda.
                                    </p>
                                </div>
                                <div className="card-body border-top p-0" hidden={receituarios.length === 0}>
                                    <div className="row g-0 align-items-center border-bottom py-2 px-3">
                                        <div className="table-responsive scrollbar">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Data</th>
                                                    <th scope="col">Dentista</th>
                                                    <th scope="col"></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {receituarios.map((receituario) => {
                                                        return <tr key={receituario.data}>
                                                            <td>
                                                                {receituario.data}
                                                            </td>
                                                            <td>
                                                                {receituario.nomeDoDentista}
                                                            </td>
                                                            <td>
                                                                <ReactToPrint
                                                                    trigger={() => {
                                                                        return <a className="btn btn-link"
                                                                                  style={{cursor: 'pointer'}}
                                                                                  title="Imprimir Receituário">
                                                                            <span className="fas fa-print"></span></a>;
                                                                    }}
                                                                    content={() => componentRefParaReceituario.current}
                                                                />
                                                                <ReceituarioParaImpressao ref={componentRefParaReceituario}
                                                                                       receituario={receituario}/>
                                                            </td>
                                                        </tr>
                                                    }
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="atestado-div" className="card mb-3">
                            <div className="card-header">
                                <div className="row flex-between-center">
                                    <div className="col col-lg-8">
                                        <h5 className="mb-0">Atestados</h5>
                                    </div>
                                    <div className="col-12 col-sm-auto ms-auto">
                                        <a className="btn btn-falcon-primary" type="button"
                                           onClick={() => abrirModalDeAtestado()}>
                                            Adicionar Novo
                                        </a>
                                    </div>
                                </div>
                                <div className="text-center" hidden={atestados.length > 0}>
                                    <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">
                                        Nenhum atestado cadastrado ainda.
                                    </p>
                                </div>
                                <div className="card-body border-top p-0" hidden={atestados.length === 0}>
                                    <div className="row g-0 align-items-center border-bottom py-2 px-3">
                                        <div className="table-responsive scrollbar">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Tipo</th>
                                                    <th scope="col">Data</th>
                                                    <th scope="col"></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {atestados.map((atestado) => {
                                                        return <tr key={atestado.id}>
                                                            <td>
                                                                {atestado.tipoDeAtestado}
                                                            </td>
                                                            <td>
                                                                {atestado.data}
                                                            </td>
                                                            <td>
                                                                <ReactToPrint
                                                                    trigger={() => {
                                                                        return <a className="btn btn-link"
                                                                                  style={{cursor: 'pointer'}}
                                                                                  title="Imprimir Atestado">
                                                                            <span className="fas fa-print"></span></a>;
                                                                    }}
                                                                    content={() => componentRefParaAtestado.current}
                                                                />
                                                                <AtestadoParaImpressao ref={componentRefParaAtestado}
                                                                                       atestado={atestado}
                                                                                       nomeDoPaciente={nomeDoPaciente}/>
                                                            </td>
                                                        </tr>
                                                    }
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="termo-div" className="card mb-3">
                            <div className="card-header">
                                <div className="row flex-between-center">
                                    <div className="col col-lg-8">
                                        <h5 className="mb-0">Termos</h5>
                                    </div>
                                    <div className="col-12 col-sm-auto ms-auto">
                                        <a className="btn btn-falcon-primary" type="button"
                                           onClick={() => abrirModalDeTermos()}>
                                            Adicionar Novo
                                        </a>
                                    </div>
                                </div>
                                <div className="text-center"
                                     hidden={termosCadastrados.length > 0}>
                                    <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">
                                        Nenhum termo cadastrado ainda.
                                    </p>
                                </div>
                                <div className="card-body border-top p-0"
                                     hidden={termosCadastrados.length === 0}>
                                    <div className="row g-0 align-items-center border-bottom py-2 px-3">
                                        <div className="table-responsive scrollbar">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Termo</th>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {termosCadastrados.map((termo) => {
                                                        return <tr key={termo.id}>
                                                            <td>
                                                                {termo.nome}
                                                            </td>
                                                            <td className="text-end">
                                                                <div>
                                                                    <a className="btn btn-link" title="Abrir Termo"
                                                                       onClick={() => abrirTermo(termo)}>
                                                                        <span className="fas fa-eye"></span>
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <ReactToPrint
                                                                    trigger={() => {
                                                                        return <a className="btn btn-link"
                                                                                  style={{cursor: 'pointer'}}
                                                                                  title="Imprimir Termo">
                                                                            <span className="fas fa-print"></span></a>;
                                                                    }}
                                                                    content={() => componentRefParaTermo.current}
                                                                />
                                                                <TermoParaImpressao ref={componentRefParaTermo}
                                                                                    html={termo.conteudo}/>
                                                            </td>
                                                        </tr>
                                                    }
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal show={exibeModalDeReceituarios} centered size={"lg"}
                               onHide={() => setExibeModalDeReceituarios(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Receituário</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form className="row g-3 card-body" onSubmit={salvarReceituario}>
                                    <div className="col-md-4">
                                        <Dentista dentistaSelecionado={idDoDentistaSelecionado}
                                                  selecionarDentista={selecionarDentista}/>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">Data</label>
                                        <DatePicker className="form-control" format="dd/MM/yyyy"
                                                    value={dataDoReceituario}
                                                    onChange={(date) => setDataDoReceituario(date)}></DatePicker>
                                    </div>
                                    <span/>
                                    <div className="col-md-8">
                                        <label className="form-label">Medicamento</label>
                                        <input className="form-control" type="text"
                                               value={nomeDoMedicamento}
                                               onChange={event => setNomeDoMedicamento(event.target.value)}/>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">Quantidade</label>
                                        <input className="form-control" type="text"
                                               value={quantidadeDoMedicamento}
                                               onChange={event => setQuantidadeDoMedicamento(event.target.value)}/>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="form-label">Posologia</label>
                                        <textarea className="form-control"
                                                  value={posologiaDoMedicamento}
                                                  onChange={event => setPosologiaDoMedicamento(event.target.value)}/>
                                    </div>
                                    <div className="col-12">
                                        <a className="btn btn-primary"
                                           onClick={() => adicionarMedicamentoNaLista()}>Adicionar
                                        </a>
                                    </div>
                                    <div>
                                        {medicamentosParaAdicionar.map((medicamento) => {
                                                return <div class="d-flex">
                                                    <span class="fas fa-book-medical text-success me-2"
                                                          data-fa-transform="down-5"></span>
                                                    <div class="flex-1">
                                                        <p class="mb-0">{medicamento.nome} -{medicamento.descricaoQuantidade} </p>
                                                        <p class="fs--1 mb-0 text-600">{medicamento.posologia}</p>
                                                        <div className="text-end mt-n5">
                                                            <button className="btn btn-link" type="button"
                                                                    title="Excluir Medicamento"
                                                                    onClick={() => removerMedicamentoDaLista(medicamento)}>
                                                                <span className="far fa-trash-alt"></span>
                                                            </button>
                                                        </div>
                                                        <div class="border-bottom border-dashed my-3"></div>
                                                    </div>
                                                </div>
                                            }
                                        )}
                                    </div>
                                    <div className="col-12" hidden={medicamentosParaAdicionar.length === 0}>
                                        <button className="btn btn-primary" type="submit">Salvar Receituário
                                        </button>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>


                        <Modal show={exibeModalDeAtestados} centered size={"lg"}
                               onHide={() => setExibeModalDeAtestados(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Atestado</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form className="row g-3 card-body" onSubmit={cadastrarAtestado}>
                                    <div className="col-md-9">
                                        <Dentista dentistaSelecionado={idDoDentistaSelecionado}
                                                  selecionarDentista={selecionarDentista}/>
                                    </div>
                                    <div className="col-md-9">
                                        <label className="form-label">Tipo de Atestado</label>
                                        <select className="form-select" required
                                                value={tipoDeAtestado}
                                                onChange={event => setTipoDeAtestado(event.target.value)}>
                                            <option value="">Selecione</option>
                                            <option value="COMPARECIMENTO">Atestado de Comparecimento na Consulta
                                            </option>
                                            <option value="REPOUSO">Atestado de Repouso</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">Data</label>
                                        <DatePicker value={dataDoAtestado} className="form-control" format="dd/MM/yyyy"
                                                    onChange={(date) => setDataDoAtestado(date)}></DatePicker>
                                    </div>
                                    <div className="col-md-2" hidden={tipoDeAtestado === 'COMPARECIMENTO'}>
                                        <label className="form-label">Dias de repouso</label>
                                        <input className="form-control" type="number"
                                               value={quantidadeDeDiasDeRepouso}
                                               onChange={event => setQuantidadeDeDiasDeRepouso(event.target.value)}/>
                                    </div>
                                    <div className="col-md-2" hidden={tipoDeAtestado === 'REPOUSO'}>
                                        <label className="form-label">Hora Inicial</label>
                                        <InputMask className="form-control" mask="99:99"
                                                   value={horaInicial}
                                                   onChange={event => setHoraInicial(event.target.value)}></InputMask>
                                    </div>
                                    <div className="col-md-2" hidden={tipoDeAtestado === 'REPOUSO'}>
                                        <label className="form-label">Hora Final</label>
                                        <InputMask className="form-control" mask="99:99"
                                                   value={horaFinal}
                                                   onChange={event => setHoraFinal(event.target.value)}></InputMask>
                                    </div>
                                    <div className="col-md-8">
                                        <label className="form-label">CID</label>
                                        <input className="form-control" type="text"
                                               value={cid}
                                               onChange={event => setCid(event.target.value)}/>
                                    </div>

                                    <div className="col-12">
                                        <button className="btn btn-primary" type="submit">Cadastrar Atestado</button>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>

                        <Modal show={exibeModalDeTermos} centered size={"lg"}
                               onHide={() => setExibeModalDeTermos(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Termo</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form onSubmit={adicionarTermo}>
                                    <div className="col-md-8">
                                        <label className="form-label">Nome</label>
                                        <input className="form-control" type="text" value={nomeDoTermo}
                                               onChange={event => setNomeDoTermo(event.target.value)}/>
                                    </div>
                                    <div className="mb-3">
                                        <Editor
                                            editorState={editor}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={setEditor}/>
                                    </div>
                                    <button className="btn btn-primary" type="submit" hidden={idDoTermo > 0}>Adicionar
                                        Termo
                                    </button>
                                    <button className="btn btn-primary" hidden={idDoTermo === 0}
                                            onClick={alterarTermo}>Salvar Alterações
                                    </button>
                                </form>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </main>
    );
}