import Usuariologado from "../../componentes/layoutBase/usuariologado";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../componentes/loading";
import MensagemParaTabelaSemDados from "../../componentes/mensagemParaTabelaSemDados";
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import {Button, Modal} from "react-bootstrap";
import MensagemDeErro from "../../componentes/mensagemDeErro";

export default function Dentista() {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const [isLoading, setLoading] = useState(true);
    const [exibeMensagemDeSucesso, setExibeMensagemDeSucesso] = useState(false);
    const [mensagemDeSucesso, setMensagemDeSucesso] = useState('');
    const [exibeMensagemDeErro, setExibeMensagemDeErro] = useState(false);
    const [mensagemDeErro, setMensagemDeErro] = useState('');
    const [exibeModalDeExclusao, setExibeModalDeExclusao] = useState(false);

    const [dentistas, setDentistas] = useState([]);
    const [nenhumObjetoEncontrado, setNenhumObjetoEncontrado] = useState(true);
    const [dentistaParaExclusao, setDentistaParaExclusao] = useState('');
    const [idDoDentista, setIdDoDentista] = useState('');
    const [nomeDoDentista, setNomeDoDentista] = useState('');
    const [estaCadastrando, setEstaCadastrando] = useState(true);

    function exibirMensagemDeErro() {
        setExibeMensagemDeSucesso(false);
        setExibeMensagemDeErro(true);
    }

    function exibirMensagemDeSucesso() {
        setExibeMensagemDeSucesso(true);
        setExibeMensagemDeErro(false);
    }

    function obterDentistas() {
        setLoading(true);
        api.get("/dentista", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setDentistas(resposta.data);
                if (resposta.data.length === 0) {
                    setNenhumObjetoEncontrado(true);
                } else {
                    setNenhumObjetoEncontrado(false);
                }
                setLoading(false);
            })
            .catch((erro) => {
                setLoading(false);
                console.error("ops! ocorreu um erro" + erro);
            });
    }

    useEffect(() => {
        obterDentistas();
    }, []);

    function abrirModalDeExclusao(dentista) {
        setExibeModalDeExclusao(true);
        setDentistaParaExclusao(dentista);
    }

    const setarNomeDoDentista = (event) => {
        setNomeDoDentista(event.target.value);
    }

    const alterarDentista = (event) => {
        event.preventDefault();
        setLoading(true);
        const dentista = {"id": idDoDentista, "nome": nomeDoDentista};
        api.put("/dentista", dentista, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                exibirMensagemDeSucesso();
                setMensagemDeSucesso('Dentista alterado com sucesso!');
                obterDentistas();
            })
            .catch((erro) => {
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                setLoading(false);
                console.error(erro);
            });

    }

    const cadastrarDentista = (event) => {
        event.preventDefault();
        setLoading(true);
        const dentista = {"nome": nomeDoDentista}

        api.post("/dentista", dentista, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                exibirMensagemDeSucesso();
                setMensagemDeSucesso('Dentista cadastrado com sucesso!');
                obterDentistas();
            })
            .catch((erro) => {
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                setLoading(false);
                console.error(erro);
            });
    }

    const removerDentista = (event) => {
        event.preventDefault();
        api.delete("/dentista/".concat(dentistaParaExclusao.id), {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                exibirMensagemDeSucesso();
                setMensagemDeSucesso('Dentista removido com sucesso!');
                obterDentistas();
            })
            .catch((erro) => {
                setLoading(false);
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                console.error(erro);
            });
        setExibeModalDeExclusao(false);
    }

    function selecionarDentista(dentista) {
        setIdDoDentista(dentista.id);
        setNomeDoDentista(dentista.nome);
        setEstaCadastrando(false);
    }

    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content" hidden={isLoading}>
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            {exibeMensagemDeSucesso &&
                                <MensagemDeSucesso mensagem={mensagemDeSucesso}/>}
                            {exibeMensagemDeErro &&
                                <MensagemDeErro mensagem={mensagemDeErro}/>}
                            <div className="row justify-content-between align-items-center">
                                <div className="col">
                                    <div className="d-flex">
                                        <h5 className="texto-negrito">Cadastro de Dentistas</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light">
                            <div className="card-body border-top p-0">
                                <div className="row g-0 align-items-center border-bottom py-2 px-3">
                                    <form className="row g-3 card-body needs-validation" onSubmit={cadastrarDentista}
                                          noValidate>
                                        <div className="col-md-6">
                                            <label className="form-label">Nome do dentista *</label>
                                            <input className="form-control" type="text"
                                                   value={nomeDoDentista} onChange={setarNomeDoDentista}/>
                                        </div>
                                        {estaCadastrando ?
                                            <div className="col-12">
                                                <button className="btn btn-primary" type="submit">Cadastrar</button>
                                            </div>
                                            :
                                            <div className="col-12">
                                                <button className="btn btn-primary" onClick={alterarDentista}>Alterar
                                                </button>
                                            </div>
                                        }
                                    </form>
                                    {nenhumObjetoEncontrado &&
                                        <MensagemParaTabelaSemDados mensagem={"Nenhum dentista cadastrado."}/>}
                                    <div className="table-responsive scrollbar" hidden={nenhumObjetoEncontrado}>
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th scope="col">Nome</th>
                                                <th className="text-end" scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {dentistas.map((dentista) => {
                                                    return <tr key={dentista.id}>
                                                        <td>
                                                            {dentista.nome}
                                                        </td>
                                                        <td className="text-end">
                                                            <a className="btn btn-link"
                                                               onClick={() => selecionarDentista(dentista)}>Editar</a>
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-link" type="button"
                                                                    onClick={() => abrirModalDeExclusao(dentista)}>
                                                                <span className="far fa-trash-alt"></span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                }
                                            )}
                                            </tbody>
                                        </table>
                                        <Modal show={exibeModalDeExclusao}
                                               onHide={() => setExibeModalDeExclusao(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Você realmente deseja remover o dentista?</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>{dentistaParaExclusao.nome}</Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary"
                                                        onClick={() => setExibeModalDeExclusao(false)}>
                                                    Cancelar
                                                </Button>
                                                <Button variant="danger" onClick={removerDentista}>
                                                    Remover
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}