import {useEffect} from "react";
import "../../css/impressao.css"

export default function TesteDeImpressaoDeTratamento() {

    useEffect(() => {
        window.print();
    }, []);

    return (
        <div className="container text-center">
            <img src="/images/logos/logoAngelaAiolfi.png" alt=""/>


            <div className="mt-5">
                <h5>Previsão de custo:</h5>

                <form className="mt-3">
                    <div>
                        <span><strong>Paciente:</strong> Anderson Josué Cardoso</span>
                    </div>

                    <div className="mt-2">
                        <span><strong>Tratamento:</strong> Tratamento Um</span>
                    </div>

                    <table className="table mt-3">
                        <thead>
                        <tr>
                            <th scope="col">Procedimento</th>
                            <th scope="col">Valor</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                Exodontia - Dente 28
                            </td>
                            <td>
                                R$450,00
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Exodontia - Dente 38
                            </td>
                            <td>
                                R$750,00
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="mt-2">
                        <span><strong>Valor Total:</strong> R$1.200,00</span>
                    </div>

                    <div className="mt-4">
                        <p className="fs--1 mb-0">
                            * No valor total, bem como o valor unitário não está incluso recibo ou Nota Fiscal (NF), para que haja  emissão da mesma, será acrescentado um valor adicional de 15% sobre o valor total.
                        </p>
                    </div>

                    <div className="mt-4">
                        <p className="fs--1 mb-0">
                            FORMAS DE PAGAMENTO:
                        </p>
                    </div>
                    <div className="mt-1">
                        <p className="fs--1 mb-0">
                            Entrada de 30% e parcelamento em até 5 vezes com cheque ou cartão de crédito (sujeito a  adicionais da máquina).
                        </p>
                    </div>

                    <div className="mt-6">
                        <p className="fs--1 mb-0">
                            Chapecó, 10 de maio de 2023.
                        </p>
                    </div>

                    <div className="mt-1">
                        <p className="fs--1 mb-0">
                            ANGELA AIOLFI CRO/SC 15921. Habilitação em Sedação Oral e uso de Óxido Nitroso na Odontologia.
                        </p>
                    </div>
                    <div className="mt-1">
                        <p className="fs--1 mb-0">
                            aaiolfi.ctbmf@gmail.com
                        </p>
                    </div>
                    <div className="mt-1">
                        <p className="fs--1 mb-0">
                            (49) 99912 7995
                        </p>
                    </div>

                </form>
            </div>


        </div>
    );
}