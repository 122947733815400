import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function ValidaAutenticacaoDoUsuario() {
    const navigate = useNavigate();

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate("/login");
        }
    });
}