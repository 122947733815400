import Logo from "../logo";
import NavBar from "../navbar";

export default function NavBarMain() {
    return (
        <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
            <Logo/>
            <NavBar/>
        </nav>
    );
}