import * as React from "react";

export class ReceituarioParaImpressao extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container d-none d-print-block">
                <div className="text-center mt-5">
                    <h5>Receituário</h5>
                </div>

                <div className="mt-5">
                    <p>Paciente: {this.props.receituario.nomeDoPaciente}</p>
                    <p>Dentista: {this.props.receituario.nomeDoDentista}</p>
                </div>

                <div className="mt-5">
                    <p>Uso Interno</p>
                    {this.props.receituario.medicamentos.map((medicamento) => {
                            return <div class="d-flex">
                                <div class="flex-1">
                                    <p class="mb-0"><strong>{medicamento.nome} - {medicamento.descricaoQuantidade}</strong></p>
                                    <p class="fs--1 mb-0 text-600">{medicamento.posologia}</p>
                                    <div class="border-bottom border-dashed my-3"></div>
                                </div>
                            </div>
                        }
                    )}
                </div>

                <div className="text-center mt-5">
                    <p>Data: {this.props.receituario.data}</p>
                    <p>______________________________</p>
                    <p>Assinatura do Profissional</p>
                </div>
            </div>
        );
    }
}