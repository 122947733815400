
export default function MensagemDeSucesso(props){

    return (
        <div className="alert alert-success border-2 d-flex align-items-center" role="alert">
            <div className="bg-success me-3 icon-item"><span className="fas fa-check-circle text-white fs-3"></span>
            </div>
            <p className="mb-0 flex-1">{props.mensagem}</p>
        </div>
    );
}