import Loading from "../../componentes/loading";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import Usuariologado from "../../componentes/layoutBase/usuariologado";
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {Button, Modal} from "react-bootstrap";

export default function ListaDePacientes() {

    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [exibeMensagemDeSucesso, setExibeMensagemDeSucesso] = useState(false);
    const [mensagemDeSucesso, setMensagemDeSucesso] = useState('');
    const [pacientes, setPacientes] = useState([]);
    const [pacientesFiltrados, setPacientesFiltrados] = useState([]);
    const [pacienteParaExclusao, setPacienteParaExclusao] = useState('');
    const [exibeModalDeExclusao, setExibeModalDeExclusao] = useState(false);

    function obterPacientes() {
        setLoading(true);
        api.get("/paciente", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setPacientes(resposta.data);
                setPacientesFiltrados(resposta.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.error("ops! ocorreu um erro" + err);
            });
    }

    useEffect(() => {
        obterPacientes();
    }, []);

    function abrirModalDeExclusao(paciente) {
        setExibeModalDeExclusao(true);
        setPacienteParaExclusao(paciente);
    }

    function abrirProntuario(paciente) {
        const dadosDoPaciente = {
            "pacienteId": paciente.id
        };
        navigate("/prontuario", {state: dadosDoPaciente});
    }

    const removerPaciente = (event) => {
        setLoading(true);
        event.preventDefault();
        api.delete("/paciente/".concat(pacienteParaExclusao.id), {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then(() => {
                setLoading(false);
                setExibeMensagemDeSucesso(true);
                setMensagemDeSucesso('Paciente removido com sucesso!');
                obterPacientes();
            })
            .catch((erro) => {
                setLoading(false);
                console.error(erro);
            });
        setExibeModalDeExclusao(false);
    }

    function filtrarPacientePorNome(event) {
        const pacientesEncontrados = [];

        pacientes.forEach((paciente) => {
            if (paciente.nome.indexOf(event) === 0) {
                pacientesEncontrados.push(paciente);
            }
        });
        setPacientesFiltrados(pacientesEncontrados);
    }

    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content" hidden={isLoading}>
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            {exibeMensagemDeSucesso &&
                                <MensagemDeSucesso mensagem={mensagemDeSucesso}/>}
                            <div className="row flex-between-center">
                                <div className="col col-lg-8">
                                    <h5 className="mb-0 texto-negrito">Pacientes Cadastrados</h5>
                                </div>
                                <div className="col-12 col-sm-auto ms-auto">
                                    <a className="btn btn-falcon-primary" onClick={() => navigate("/paciente")}
                                       type="button">Cadastrar Novo Paciente</a>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light">
                            <form className="row g-3 card-body needs-validation">
                                <div className="col-md-6">
                                    <input className="form-control" type="text"
                                           placeholder="Filtrar paciente por nome"
                                           onChange={event => filtrarPacientePorNome(event.target.value)}/>
                                </div>
                            </form>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Cpf</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {pacientesFiltrados.map((paciente) => {
                                        return <tr key={paciente.id}>
                                            <td>
                                                {paciente.nome}
                                            </td>
                                            <td>
                                                {paciente.cpf}
                                            </td>
                                            <td className="text-end">
                                                <a className="btn btn-link" onClick={() => abrirProntuario(paciente)}>Prontuário</a>
                                            </td>
                                            <td>
                                                <button className="btn btn-link" type="button"
                                                        onClick={() => abrirModalDeExclusao(paciente)}>
                                                    <span className="far fa-trash-alt"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    }
                                )}
                                </tbody>
                            </table>
                            <Modal show={exibeModalDeExclusao}
                                   onHide={() => setExibeModalDeExclusao(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Você realmente deseja remover o paciente?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{pacienteParaExclusao.nome}</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary"
                                            onClick={() => setExibeModalDeExclusao(false)}>
                                        Cancelar
                                    </Button>
                                    <Button variant="danger" onClick={removerPaciente}>
                                        Remover
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}