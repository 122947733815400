import * as React from "react";


export class AtestadoParaImpressao extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container d-none d-print-block">
                <div className="text-center mt-5">
                    <h5>ATESTADO</h5>
                </div>

                <div
                    className={this.props.atestado.tipoDeAtestadoValue === 'REPOUSO' ? 'd-none d-print-block mt-5' : 'd-print-none'}>
                    <p>Atesto, com o fim específico de dispensa de atividades trabalhistas (ou escolares, judiciárias),
                        que o (a) paciente {this.props.nomeDoPaciente} esteve sob meus cuidados profissionais no
                        dia {this.props.atestado.data} devendo permanecer em repouso
                        por XX dia(s).</p>
                </div>

                <div
                    className={this.props.atestado.tipoDeAtestadoValue === 'COMPARECIMENTO' ? 'd-none d-print-block mt-5' : 'd-print-none'}>
                    <p>Atesto, com o fim específico de dispensa de atividades trabalhistas (ou escolares, judiciárias),
                        que o (a) paciente {this.props.nomeDoPaciente} esteve sob meus cuidados profissionais no
                        dia {this.props.atestado.data},
                        no período de {this.props.atestado.horaInicial} horas
                        às {this.props.atestado.horaFinal} horas.</p>
                </div>

                <div className="text-center mt-5">
                    <p>Atenciosamente,</p>
                    <p>{this.props.atestado.nomeDoDentista}</p>
                </div>

                <div className="text-center mt-5">
                    <p>Autorização</p>
                    <p>Eu, ___________________________________________</p>
                    <p>autorizo que meu diagnóstico(CID) esteja presente nesse atestado.</p>
                    <p>CID: {this.props.atestado.cid}</p>
                </div>
            </div>
        );
    }
}