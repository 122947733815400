import Usuariologado from "../../componentes/layoutBase/usuariologado";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../componentes/loading";
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import MensagemDeErro from "../../componentes/mensagemDeErro";

export default function AlteracaoDeSenha() {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const [isLoading, setLoading] = useState(false);
    const [exibeMensagemDeSucesso, setExibeMensagemDeSucesso] = useState(false);
    const [mensagemDeSucesso, setMensagemDeSucesso] = useState('');
    const [exibeMensagemDeErro, setExibeMensagemDeErro] = useState(false);
    const [mensagemDeErro, setMensagemDeErro] = useState('');

    const [senhaAtual, setSenhaAtual] = useState('');
    const [novaSenha, setNovaSenha] = useState('');
    const [confirmacaoDeSenha, setConfirmacaoDeSenha] = useState('');

    function exibirMensagemDeErro() {
        setExibeMensagemDeSucesso(false);
        setExibeMensagemDeErro(true);
    }

    function exibirMensagemDeSucesso() {
        setExibeMensagemDeSucesso(true);
        setExibeMensagemDeErro(false);
    }

    useEffect(() => {
    }, []);

    const alterarSenha = (event) => {
        event.preventDefault();
        setLoading(true);

        const usuario = {
            'senhaAtual': senhaAtual,
            'senhaNova': novaSenha,
            'confirmacaoDeSenha': confirmacaoDeSenha
        };

        console.log('usuario', usuario);
        api.put("/usuario/senha", usuario, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                exibirMensagemDeSucesso();
                setMensagemDeSucesso('Senha alterada com sucesso!');
            })
            .catch((erro) => {
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                setLoading(false);
                console.error(erro);
            });
    }

    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content" hidden={isLoading}>
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            {exibeMensagemDeSucesso &&
                                <MensagemDeSucesso mensagem={mensagemDeSucesso}/>}
                            {exibeMensagemDeErro &&
                                <MensagemDeErro mensagem={mensagemDeErro}/>}
                            <div className="row justify-content-between align-items-center">
                                <div className="col">
                                    <div className="d-flex">
                                        <h5 className="texto-negrito">Alteração de Senha</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light">
                            <div className="card-body border-top p-0">
                                <div className="row g-0 align-items-center border-bottom py-2 px-3">
                                    <form className="row g-3 card-body needs-validation" onSubmit={alterarSenha}
                                          noValidate>
                                        <div className="col-md-6">
                                            <label className="form-label">Senha Atual</label>
                                            <input className="form-control" type="password"
                                                   value={senhaAtual}
                                                   onChange={event => setSenhaAtual(event.target.value)}/>
                                        </div>
                                        <span/>
                                        <div className="col-md-6">
                                            <label className="form-label">Nova Senha</label>
                                            <input className="form-control" type="password"
                                                   value={novaSenha}
                                                   onChange={event => setNovaSenha(event.target.value)}/>
                                        </div>
                                        <span/>
                                        <div className="col-md-6">
                                            <label className="form-label">Confirmar Nova Senha</label>
                                            <input className="form-control" type="password"
                                                   value={confirmacaoDeSenha}
                                                   onChange={event => setConfirmacaoDeSenha(event.target.value)}/>
                                        </div>

                                        <div className="col-12">
                                            <button className="btn btn-primary" type="submit">Alterar Senha</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}