import * as React from "react";
import "../../css/impressao.css"

export class TratamentoParaImpressao extends React.PureComponent {

    constructor(props) {
        super(props);
        this.urlLogo = sessionStorage.getItem('url_logo');
    }

    render() {
        let procedimentosDoTratamento = [];
        if (this.props.tratamento) {
            procedimentosDoTratamento = this.props.tratamento.procedimentos;
        }
        return (
            <div className="container text-center d-none d-print-block">
                <img src={this.urlLogo} width={200} height={200} alt=""/>
                <div className="mt-5">
                    <h5>Previsão de custo:</h5>

                    <form className="mt-3">
                        <div>
                            <span><strong>Paciente: </strong>{this.props.tratamento.nomeDoPaciente}</span>
                        </div>

                        <div className="mt-2">
                            <span><strong>Tratamento: </strong>{this.props.tratamento.descricaoDoTratamento}</span>
                        </div>

                        <table className="table mt-3">
                            <thead>
                            <tr>
                                <th scope="col">Procedimento</th>
                                <th scope="col">Valor</th>
                            </tr>
                            </thead>
                            <tbody>
                            {procedimentosDoTratamento.map((procedimento) => {
                                return <tr key={procedimento.id}>
                                    <td>
                                        {procedimento.descricao} - Dente {procedimento.dentePorExtenso}
                                    </td>
                                    <td>
                                        {procedimento.valor}
                                    </td>
                                </tr>
                            })
                            }
                            </tbody>
                        </table>
                        <div className="mt-2">
                            <span><strong>Valor Total:</strong> {this.props.tratamento.valorTotalDoTratamento}</span>
                        </div>

                        <div className="mt-4">
                            <p className="fs--1 mb-0">
                                {this.props.tratamento.observacaoUm}
                            </p>
                        </div>

                        <div className="mt-4">
                            <p className="fs--1 mb-0">
                                {this.props.tratamento.tituloUm}
                            </p>
                        </div>
                        <div className="mt-1">
                            <p className="fs--1 mb-0">
                                {this.props.tratamento.conteudoUm}
                            </p>
                        </div>

                        <div className="mt-6">
                            <p className="fs--1 mb-0">
                                {this.props.tratamento.nomeDaCidade}, {this.props.tratamento.dataAtualPorExtenso}.
                            </p>
                        </div>

                        <div className="mt-1">
                            <p className="fs--1 mb-0">
                                {this.props.tratamento.nomeDaClinica}
                            </p>
                        </div>
                        <div className="mt-1">
                            <p className="fs--1 mb-0">
                                {this.props.tratamento.email}
                            </p>
                        </div>
                        <div className="mt-1">
                            <p className="fs--1 mb-0">
                                {this.props.tratamento.telefone}
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}