import Loading from "../../componentes/loading";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import {useEffect, useState} from "react";
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import "../../css/datePicker.css"
import 'react-calendar/dist/Calendar.css';
import DatePicker from "react-date-picker";
import InputMask from 'react-input-mask';
import Usuariologado from "../../componentes/layoutBase/usuariologado";
import axios from "axios";
import MensagemDeErro from "../../componentes/mensagemDeErro";

export default function Paciente() {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const [isLoading, setLoading] = useState(false);
    const [exibeMensagemDeSucesso, setExibeMensagemDeSucesso] = useState(false);
    const [mensagemDeSucesso, setMensagemDeSucesso] = useState('');
    const [exibeMensagemDeErro, setExibeMensagemDeErro] = useState(false);
    const [mensagemDeErro, setMensagemDeErro] = useState('');
    const [pacienteSelecionado] = useState('');

    const [nome, setNome] = useState('');
    const [dataDeNascimento, setDataDeNascimento] = useState();
    const [sexo, setSexo] = useState('');
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [telefone, setTelefone] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [complemento, setComplemento] = useState('');
    const [indicadoPor, setIndicadoPor] = useState('');
    const [cirurgiaoAnterior, setCirurgiaoAnterior] = useState('');
    const [contatoDeEmergencia, setContatoDeEmergencia] = useState('');
    const [creditoPrimeiraConsulta, setCreditoPrimeiraConsulta] = useState(0);
    const [nomeDoResponsavel, setNomeDoResponsavel] = useState('');
    const [dataDeNascimentoDoResponsavel, setDataDeNascimentoDoResponsavel] = useState();
    const [cpfDoResponsavel, setCpfDoResponsavel] = useState('');
    const [telefoneDoResponsavel, setTelefoneDoResponsavel] = useState('');
    const [parentescoDoResponsavel, setParentescoDoResponsavel] = useState('');
    const [cidades, setCidades] = useState([]);

    useEffect(() => {
        obterCidades();
    }, []);

    function exibirMensagemDeErro() {
        setExibeMensagemDeSucesso(false);
        setExibeMensagemDeErro(true);
    }

    function exibirMensagemDeSucesso() {
        setExibeMensagemDeSucesso(true);
        setExibeMensagemDeErro(false);
    }

    function obterCidades() {
        api.get("/cidade", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setCidades(resposta.data);
            })
            .catch((erro) => {
                setLoading(false);
                console.error(erro);
            });
    }

    const cadastrarPaciente = (event) => {
        event.preventDefault();
        setLoading(true);
        const paciente = {
            "nome": nome,
            "cpf": cpf,
            "rg": rg,
            "sexo": sexo,
            "dataDeNascimento": dataDeNascimento,
            "telefone": telefone,
            "rua": rua,
            "numero": numero,
            "bairro": bairro,
            "cidade": cidade,
            "complementoDeEndereco": complemento,
            "nomeDoResponsavel": nomeDoResponsavel,
            "dataDeNascimentoDoResponsavel": dataDeNascimentoDoResponsavel,
            "cpfDoResponsavel": cpfDoResponsavel,
            "telefoneDoResponsavel": telefoneDoResponsavel,
            "parentescoDoResponsavel": parentescoDoResponsavel,
            "nomeDeQuemIndicou": indicadoPor,
            "contatoDeEmergencia": contatoDeEmergencia,
            "nomeDoCirurgiaoDentistaAnterior": cirurgiaoAnterior,
            "creditoDaPrimeiraConsulta": creditoPrimeiraConsulta
        };

        api.post("/paciente", paciente, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                exibirMensagemDeSucesso()
                setMensagemDeSucesso('Paciente cadastrado com sucesso!');
            })
            .catch((erro) => {
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                setLoading(false);
                console.error(erro);
            });
    }

    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content" hidden={isLoading}>
                    <Usuariologado/>
                    <div className="card mb-3 d-print-none">
                        <div className="card-header">
                            <div className="row justify-content-between align-items-center">
                                <div className="col">
                                    <div className="d-flex">
                                        <div>
                                            <h5 className="texto-negrito">Cadastro de Paciente</h5>
                                        </div>
                                        <div className="avatar avatar-xl" hidden={pacienteSelecionado != null}>
                                            <img className="rounded-circle" src={"/js/tema_falcon/thumb.png"}
                                                 alt=""/>
                                        </div>
                                        <div className="flex-1 fs--1" hidden={pacienteSelecionado != null}>
                                            <h5 className="fs-0">{pacienteSelecionado.nome}</h5>
                                            <p className="mb-0">{pacienteSelecionado.telefone}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light">
                            {exibeMensagemDeSucesso &&
                                <MensagemDeSucesso mensagem={mensagemDeSucesso}/>}
                            {exibeMensagemDeErro &&
                                <MensagemDeErro mensagem={mensagemDeErro}/>}
                            <form className="row g-3 card-body needs-validation" onSubmit={cadastrarPaciente}
                                  noValidate>
                                <div className="col-md-4">
                                    <label className="form-label">Nome</label>
                                    <input id="nome" className="form-control" type="text" value={nome}
                                           onChange={event => setNome(event.target.value)} required/>
                                    <div className="invalid-feedback">
                                        é obrigatório informar o nome.
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label" htmlFor="dataDeNascimento">Data de Nascimento</label>
                                    <DatePicker value={dataDeNascimento} className="form-control"
                                                onChange={(date) => setDataDeNascimento(date)}
                                                format="dd/MM/yyyy"></DatePicker>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Sexo</label>
                                    <select className="form-select"
                                            value={sexo} onChange={event => setSexo(event.target.value)}
                                            required>
                                        <option value="0">Selecione</option>
                                        <option value="MASCULINO">Masculino</option>
                                        <option value="FEMININO">Feminino</option>
                                    </select>
                                    <div className="invalid-feedback">
                                        é obrigatório informar o sexo.
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Cpf</label>
                                    <InputMask className="form-control" mask="999.999.999-99"
                                               value={cpf} onChange={event => setCpf(event.target.value)}></InputMask>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Rg</label>
                                    <input className="form-control" type="number"
                                           value={rg} onChange={event => setRg(event.target.value)}/>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Telefone</label>
                                    <InputMask className="form-control" mask="(99)99999-9999"
                                               value={telefone}
                                               onChange={event => setTelefone(event.target.value)}></InputMask>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Rua</label>
                                    <input className="form-control" type="text"
                                           value={rua} onChange={event => setRua(event.target.value)}/>
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">Número</label>
                                    <input className="form-control" type="text"
                                           value={numero} onChange={event => setNumero(event.target.value)}/>
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">Bairro</label>
                                    <input className="form-control" type="text"
                                           value={bairro} onChange={event => setBairro(event.target.value)}/>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Cidade</label>
                                    <select className="form-select" required
                                            value={cidade} onChange={event => setCidade(event.target.value)}>
                                        <option value="0">Selecione</option>
                                        {cidades.map((cidade) => {
                                                return <option key={cidade.value} value={cidade.value}>{cidade.nome}</option>
                                            }
                                        )}
                                    </select>
                                    <div className="invalid-feedback">
                                        é obrigatório informar a cidade.
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <label className="form-label">Complemento</label>
                                    <input className="form-control" type="text"
                                           value={complemento} onChange={event => setComplemento(event.target.value)}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Indicado por</label>
                                    <input className="form-control" type="text"
                                           value={indicadoPor} onChange={event => setIndicadoPor(event.target.value)}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Cirurgião Dentista Anterior</label>
                                    <input className="form-control" type="text"
                                           value={cirurgiaoAnterior}
                                           onChange={event => setCirurgiaoAnterior(event.target.value)}/>
                                </div>
                                <div className="mb-3 mt-3">
                                    <label className="form-label">Em caso de emergência contactar</label>
                                    <textarea className="form-control" id="contatoParaEmergencia" rows="2"
                                              value={contatoDeEmergencia}
                                              onChange={event => setContatoDeEmergencia(event.target.value)}/>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Crédito da primeira consulta</label>
                                    <div className="input-group">
                                        <span className="input-group-text">R$</span>
                                        <input className="form-control" id="currency" type="number"
                                               value={creditoPrimeiraConsulta}
                                               onChange={event => setCreditoPrimeiraConsulta(event.target.value)}/>
                                    </div>
                                </div>

                                <h4>Dados do responsável</h4>

                                <div className="col-md-6">
                                    <label className="form-label">Nome</label>
                                    <input className="form-control" type="text"
                                           value={nomeDoResponsavel}
                                           onChange={event => setNomeDoResponsavel(event.target.value)}/>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Data de
                                        Nascimento</label>
                                    <DatePicker value={dataDeNascimentoDoResponsavel} className="form-control" format="dd/MM/yyyy"
                                                onChange={(date) => setDataDeNascimentoDoResponsavel(date)}></DatePicker>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Cpf</label>
                                    <InputMask className="form-control" mask="999.999.999-99"
                                               value={cpfDoResponsavel}
                                               onChange={event => setCpfDoResponsavel(event.target.value)}></InputMask>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Telefone</label>
                                    <InputMask className="form-control" mask="(99)99999-9999"
                                               value={telefoneDoResponsavel}
                                               onChange={event => setTelefoneDoResponsavel(event.target.value)}></InputMask>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label">Parentesco</label>
                                    <input className="form-control" type="text"
                                           value={parentescoDoResponsavel}
                                           onChange={event => setParentescoDoResponsavel(event.target.value)}/>
                                </div>

                                <div className="col-12">
                                    <button className="btn btn-primary" type="submit">Cadastrar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}