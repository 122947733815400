import ValidaAutenticacaoDoUsuario from "../../validaAutenticacaoDoUsuario";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";

export default function Usuariologado() {
    const URL_PADRAO = require('../../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const navigate = useNavigate();

    const [usuarioLogado, setUsuarioLogado] = useState('');
    const [ehUsuarioAdmin, setEhUsuarioAdmin] = useState(false);

    useEffect(() => {
        obterUsuarioLogado();
    }, []);

    const fazerLogout = (event) => {
        sessionStorage.removeItem('token');
        navigate("/login");
    }

    function obterUsuarioLogado() {
        api.get("/usuario", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setUsuarioLogado(resposta.data);
                if (resposta.data === 'admin@gmail.com') {
                    setEhUsuarioAdmin(true);
                }
            })
            .catch((err) => {
                console.error("Erro ao obter usuário Logado" + err);
                navigate("/login");
            });
    }

    return (
        <nav className="navbar navbar-light navbar-glass navbar-top navbar-expand">
            <ValidaAutenticacaoDoUsuario/>
            <ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center d-print-none">
                <li className="nav-item dropdown">
                    <a className="nav-link pe-0 ps-2" id="navbarDropdownUser" role="button"
                       href="/sigo/inicio"
                       data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div className="avatar avatar-xl">
                            <img className="rounded-circle" src="/images/thumb.png" alt=""/>
                        </div>
                    </a>
                    <div className="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0"
                         aria-labelledby="navbarDropdownUser">
                        <div className="bg-white dark__bg-1000 rounded-2 py-2">
                            <a className="dropdown-item">{usuarioLogado}</a>
                            <div className="dropdown-divider"></div>
                            <a href="/cliente" className="dropdown-item" hidden={!ehUsuarioAdmin}>Clientes</a>
                            <a href="/alteracao-de-senha" className="dropdown-item">Alterar Senha</a>
                            <a className="dropdown-item" href="#" onClick={fazerLogout}>Sair</a>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    );
}