export default function NavBar(){
    return (
        <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
            <div className="navbar-vertical-content scrollbar">
                <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
                    <li className="nav-item">
                    </li>
                    <li className="nav-item mt-3">
                        <a className="nav-link" href="/inicio" role="button">
                            <div className="d-flex align-items-center"><span className="nav-link-icon"><span
                                className="fas fa-home"></span></span><span
                                className="nav-link-text ps-1">Início</span>
                            </div>
                        </a>
                        <div className="row navbar-vertical-label-wrapper">
                            <div className="col-auto navbar-vertical-label">
                            </div>
                            <div className="col ps-0">
                                <hr className="mb-0 navbar-vertical-divider"/>
                            </div>
                        </div>
                        <a className="nav-link" href="/agenda" role="button">
                            <div className="d-flex align-items-center"><span className="nav-link-icon"><span
                                className="fas fa-calendar-alt"></span></span><span
                                className="nav-link-text ps-1">Agenda</span>
                            </div>
                        </a>
                        <a className="nav-link dropdown-indicator" href="#paciente" role="button"
                           data-bs-toggle="collapse" aria-expanded="false"
                           aria-controls="email">
                            <div className="d-flex align-items-center">
                                        <span className="nav-link-icon">
                                            <span className="fas fa-user"></span>
                                        </span>
                                <span className="nav-link-text ps-1">Paciente</span>
                            </div>
                        </a>
                        <ul className="nav collapse" id="paciente">
                            <li className="nav-item"><a className="nav-link"
                                                        href="/pacientes">
                                <div className="d-flex align-items-center"><span
                                    className="nav-link-text ps-1">Todos</span>
                                </div>
                            </a>
                            </li>
                            <li className="nav-item"><a className="nav-link"
                                                        href="/paciente">
                                <div className="d-flex align-items-center"><span
                                    className="nav-link-text ps-1">Cadastrar Novo</span>
                                </div>
                            </a>
                            </li>
                        </ul>
                        <a className="nav-link dropdown-indicator" href="#dentista" role="button"
                           data-bs-toggle="collapse" aria-expanded="false"
                           aria-controls="dentista">
                            <div className="d-flex align-items-center">
                                        <span className="nav-link-icon">
                                            <span className="fas fa-user-md"></span>
                                        </span>
                                <span className="nav-link-text ps-1">Dentista</span>
                            </div>
                        </a>
                        <ul className="nav collapse" id="dentista">
                            <li className="nav-item"><a className="nav-link" href="/dentista">
                                <div className="d-flex align-items-center"><span
                                    className="nav-link-text ps-1">Cadastro</span>
                                </div>
                            </a>
                            </li>
                        </ul>
                        <a className="nav-link dropdown-indicator" href="#impressoes" role="button"
                           data-bs-toggle="collapse" aria-expanded="false"
                           aria-controls="dentista">
                            <div className="d-flex align-items-center">
                                        <span className="nav-link-icon">
                                            <span className="fas fa-print"></span>
                                        </span>
                                <span className="nav-link-text ps-1">Impressões</span>
                            </div>
                        </a>
                        <ul className="nav collapse" id="impressoes">
                            <li className="nav-item"><a className="nav-link" href="/configuracao-de-impressao-do-tratamento">
                                <div className="d-flex align-items-center"><span
                                    className="nav-link-text ps-1">Tratamento</span>
                                </div>
                            </a>
                            </li>
                        </ul>
                        <div className="row navbar-vertical-label-wrapper">
                            <div className="col-auto navbar-vertical-label">
                            </div>
                            <div className="col ps-0">
                                <hr className="mb-0 navbar-vertical-divider"/>
                            </div>
                        </div>
                        <a className="nav-link dropdown-indicator" href="#financeiro" role="button"
                           data-bs-toggle="collapse" aria-expanded="false"
                           aria-controls="financeiro">
                            <div className="d-flex align-items-center">
                                        <span className="nav-link-icon">
                                            <span className="fas fa-dollar-sign"></span>
                                        </span>
                                <span className="nav-link-text ps-1">Financeiro</span>
                            </div>
                        </a>
                        <ul className="nav collapse" id="financeiro">
                            <li className="nav-item"><a className="nav-link" href="/despesa">
                                <div className="d-flex align-items-center"><span
                                    className="nav-link-text ps-1">Depesas</span>
                                </div>
                            </a>
                            </li>
                            <li className="nav-item"><a className="nav-link" href="/configuracoes-financeiras">
                                <div className="d-flex align-items-center"><span
                                    className="nav-link-text ps-1">Configurações</span>
                                </div>
                            </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
}