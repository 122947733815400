import Usuariologado from "../../componentes/layoutBase/usuariologado";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../componentes/loading";
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import {Modal} from "react-bootstrap";
import MensagemDeErro from "../../componentes/mensagemDeErro";
import ModalAvisoSemPermissao from "../../componentes/modal.avisos.sempermissao";
import CurrencyInput from "react-currency-input-field";

export default function Expense() {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const [isLoading, setLoading] = useState(false);
    const [exibeMensagemDeSucesso, setExibeMensagemDeSucesso] = useState(false);
    const [mensagemDeSucesso, setMensagemDeSucesso] = useState('');
    const [exibeMensagemDeErro, setExibeMensagemDeErro] = useState(false);
    const [mensagemDeErro, setMensagemDeErro] = useState('');
    const [exibeModalParaAdicionarDespesa, setExibeModalParaAdicionarDespesa] = useState(false);

    // ### DADOS DA DESPESA
    const [descricao, setDescricao] = useState('');
    const [valor, setValor] = useState(0);
    const [tipo, setTipo] = useState('');
    const [dataDeVencimento, setDataDeVencimento] = useState('');
    const [contaId, setContaId] = useState('');

    function exibirMensagemDeErro() {
        setExibeMensagemDeSucesso(false);
        setExibeMensagemDeErro(true);
    }

    function exibirMensagemDeSucesso() {
        setExibeMensagemDeSucesso(true);
        setExibeMensagemDeErro(false);
    }

    useEffect(() => {
    }, []);

    function abrirModalDeCadastroDeDespesa() {
        setExibeModalParaAdicionarDespesa(true);
    }

    function fecharModalDeCadastroDeDespesa() {
        setExibeModalParaAdicionarDespesa(false);
    }

    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content" hidden={isLoading}>
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            {exibeMensagemDeSucesso &&
                                <MensagemDeSucesso mensagem={mensagemDeSucesso}/>}
                            {exibeMensagemDeErro &&
                                <MensagemDeErro mensagem={mensagemDeErro}/>}
                            <ModalAvisoSemPermissao/>
                            <div className="row flex-between-center">
                                <div className="col col-lg-8">
                                    <h5 className="mb-0 texto-negrito">Despesas</h5>
                                </div>
                                <div className="col-12 col-sm-auto ms-auto">
                                    <a className="btn btn-falcon-primary"
                                       onClick={() => abrirModalDeCadastroDeDespesa()}
                                       type="button">Cadastrar Nova Despesa</a>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light">
                            <div className="card-body border-top p-0">
                                <div className="row g-0 align-items-center border-bottom py-2 px-3">
                                </div>
                            </div>
                        </div>

                        <Modal show={exibeModalParaAdicionarDespesa} centered size={"lg"}
                               onHide={() => fecharModalDeCadastroDeDespesa()}>
                            <Modal.Header closeButton>
                                <Modal.Title>Despesa</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form className="row g-3 card-body needs-validation" action="#">
                                    <div className="col-md-7">
                                        <label className="form-label">Descrição</label>
                                        <input className="form-control" type="text" value={descricao}
                                               onChange={event => setDescricao(event.target.value)}/>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label">Valor</label>
                                        <div className="input-group">
                                            <span className="input-group-text">R$</span>
                                            <CurrencyInput className="form-control"
                                                           decimalSeparator="," groupSeparator="."
                                                           decimalScale="2"
                                                           defaultValue={valor}
                                                           decimalsLimit={2}
                                                           onChange={event => setValor(event.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <label className="form-label">Tipo</label>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Data de Vencimento</label>
                                        {/*<DatePicker value={dataDaConsulta} className="form-control"*/}
                                        {/*            onChange={(date) => setDataDaConsulta(date)}*/}
                                        {/*            format="dd/MM/yyyy"></DatePicker>*/}
                                    </div>
                                    <div className="col-md-2">
                                        {/*<label className="form-label">Hora de Início</label>*/}
                                        {/*<InputMask className="form-control" mask="99:99"*/}
                                        {/*           value={horaDeInicioDaConsulta}*/}
                                        {/*           onChange={event => setHoraDeInicioDaConsulta(event.target.value)}></InputMask>*/}
                                    </div>
                                    <div className="col-md-2">
                                        {/*<input className="form-control" type="number" value={duracaoDaConsulta}*/}
                                        {/*       onChange={event => setDuracaoDaConsulta(event.target.value)}/>*/}
                                    </div>
                                    <div className="mb-3">
                                        {/*<textarea className="form-control" rows="3"*/}
                                        {/*          value={observacoesDaConsulta}*/}
                                        {/*          onChange={event => setObservacoesDaConsulta(event.target.value)}></textarea>*/}
                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label">Tipo</label>
                                        {/*<select className="form-select" value={tipoDaConsulta}*/}
                                        {/*        onChange={event => setTipoDaConsulta(event.target.value)}>*/}
                                        {/*    <option value="INVALIDO">Selecione o tipo</option>*/}
                                        {/*    <option value="AVALIACAO">Avaliação</option>*/}
                                        {/*    <option value="ORTODONTIA">Ortodontia</option>*/}
                                        {/*    <option value="CIRURGIA">Cirurgia</option>*/}
                                        {/*    <option value="PROTESE">Prótese</option>*/}
                                        {/*    <option value="DENTISTICA">Dentística</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div className="col-md-6">
                                        {/*<a className="btn btn-link mt-4"*/}
                                        {/*   onClick={() => irParaProntuarioDoPaciente(idDoPacienteSelecionado)}>Ir para prontuário do paciente</a>*/}
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary" type="submit">
                                            {/*        hidden={idDaConsultaSelecionada > 0}>*/}
                                            Salvar
                                        </button>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>

                    </div>
                </div>
            </div>
        </main>
    );
}