import axios from "axios";
import {useEffect, useState} from "react";

export default function Paciente(props) {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const [pacientes, setPacientes] = useState([]);
    const [semLabel, setSemLabel] = useState(false);

    useEffect(() => {
        obterPacientes();
        if (props.semLabel) {
            setSemLabel(true);
        }
    }, []);

    function obterPacientes() {
        api.get("/paciente", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setPacientes(resposta.data);
            })
            .catch((erro) => {
                console.error(erro);
            });
    }

    return (
        <div>
            <label className="form-label" hidden={semLabel}>Paciente</label>
            <div className="tab-pane preview-tab-pane active" role="tabpanel">
                <select className="form-select" required
                        value={props.pacienteSelecionado} onChange={props.selecionarPaciente}>
                    <option value="0">Selecione</option>
                    {pacientes.map((paciente) => {
                            return <option key={paciente.id} value={paciente.id}>{paciente.nome}</option>
                        }
                    )}
                </select>
            </div>
            <div className="invalid-feedback">
                é obrigatório informar o paciente.
            </div>
        </div>
    );
}