import NavBarMain from "../../componentes/layoutBase/navbarmain";
import Usuariologado from "../../componentes/layoutBase/usuariologado";
import {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import Loading from "../../componentes/loading";
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import MensagemDeErro from "../../componentes/mensagemDeErro";
import ModalAvisoSemPermissao from "../../componentes/modal.avisos.sempermissao";


export default function ExpenseType() {
    const DEFAULT_URL = require('../../api/url.json').api;
    const api = axios.create({baseURL: DEFAULT_URL});
    const [isLoading, setLoading] = useState(false);
    const [isToShowSucessMessage, setIsToShowSucessMessage] = useState(false);
    const [isToShowDeleteModal, setIsToShowDeleteModal] = useState(false);
    const [isToShowAddModal, setIsToShowAddModal] = useState(false);
    const [isToShowErrorMessage, setIsToShowErrorMessage] = useState(false);
    const [sucessMessage, setSucessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [expenseTypesAdded, setExpenseTypesAdded] = useState([]);

    const [id, setId] = useState('');
    const [description, setDescription] = useState('');
    const [complement, setComplement] = useState('');

    useEffect(() => {
        getExpenseTypes();
    }, []);

    function getExpenseTypes() {
        api.get("/expensetype", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((response) => {
            setExpenseTypesAdded(response.data);
        }).catch((err) => {
            console.error("ops! some error occour" + err);
        });
    }

    function cleanFields() {
        setId(null);
        setDescription('');
        setComplement('');
    }

    function openModelToAddExpenseType() {
        cleanFields();
        setIsToShowAddModal(true);
    }

    function showErrorMessage() {
        setIsToShowSucessMessage(false);
        setIsToShowErrorMessage(true);
    }

    function showSucessMessage() {
        setIsToShowSucessMessage(true);
        setIsToShowErrorMessage(false);
    }

    const addExpenseType = (event) => {
        event.preventDefault();
        setLoading(true);
        const expenseType = {
            "description": description,
            "complement": complement
        }
        api.post("/expensetype", expenseType, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then(() => {
                setLoading(false);
                showSucessMessage();
                setSucessMessage('Tipo De Despesa cadastrado com sucesso!');
                setIsToShowAddModal(false);
                cleanFields();
                getExpenseTypes();
            })
            .catch((erro) => {
                showErrorMessage();
                setErrorMessage(erro.response.data);
                setLoading(false);
                console.error(erro);
            });
    }

    const updateExpenseType = (event) => {
        event.preventDefault();
        setLoading(true);
        const expenseType = {
            "id": id,
            "description": description,
            "complement": complement
        }
        api.put("/expensetype", expenseType, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then(() => {
                setLoading(false);
                showSucessMessage();
                setSucessMessage('Tipo De Despesa alterada com sucesso!');
                setIsToShowAddModal(false);
                cleanFields();
                getExpenseTypes();
            })
            .catch((erro) => {
                showErrorMessage();
                setIsToShowErrorMessage(erro.response.data);
                setLoading(false);
                console.error(erro);
            });
    }

    const deleteExpenseType = (event) => {
        event.preventDefault();
        setLoading(true);
        api.delete("/expensetype/".concat(id), {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then(() => {
                setLoading(false);
                showSucessMessage();
                setSucessMessage('Tipo De Despesa cadastrado com sucesso!');
                setIsToShowDeleteModal(false);
                cleanFields();
                getExpenseTypes();
            })
            .catch((erro) => {
                setIsToShowDeleteModal(false);
                showErrorMessage();
                setErrorMessage(erro.response.data);
                setLoading(false);
                console.error(erro);
            });
    }

    function openExpenseType(expenseType) {
        setId(expenseType.id);
        setDescription(expenseType.description);
        setComplement(expenseType.complement);
        setIsToShowAddModal(true);
    }

    function openDeleteModal(expenseType) {
        setIsToShowDeleteModal(true);
        setId(expenseType.id);
    }


    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content">
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="row justify-content-between align-items-center">
                                <div className="col col-lg-8 mb-4">
                                    <h5 className="mb-0">Configurações Financeiras</h5>
                                </div>
                            </div>
                        </div>
                        <ModalAvisoSemPermissao/>
                        {isToShowSucessMessage &&
                            <MensagemDeSucesso mensagem={sucessMessage}/>}
                        {isToShowErrorMessage &&
                            <MensagemDeErro mensagem={errorMessage}/>}
                        <div className="card-body bg-light">
                            <div className="col-12 col-sm-auto ms-auto">
                                <button className="btn btn-falcon-primary m-md-1" type="button" disabled={true}>Tipo De
                                    Despesa
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button">Tipo De Receita</button>
                            </div>
                        </div>
                        <div className="col-12 col-sm-auto ms-auto">
                            <a className="btn btn-falcon-primary" type="button"
                               onClick={() => openModelToAddExpenseType()}>
                                Novo Tipo de Despesa
                            </a>
                        </div>

                        <div className="text-center" hidden={expenseTypesAdded.length > 0}>
                            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">
                                Nenhum tipo de despesa cadastrado ainda.
                            </p>
                        </div>
                        <div className="card-body border-top p-0" hidden={expenseTypesAdded.length === 0}>
                            <div className="row g-0 align-items-center border-bottom py-2 px-3">
                                <div className="table-responsive scrollbar">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Descrição</th>
                                            <th scope="col">Complemento</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {expenseTypesAdded.map((expenseType) => {
                                            return <tr key={expenseType.id}>
                                                <td>
                                                    {expenseType.description}
                                                </td>
                                                <td>
                                                    {expenseType.complement}
                                                </td>
                                                <td className="text-end">
                                                    <div>
                                                        <a className="btn btn-link" title="Editar"
                                                           onClick={() => openExpenseType(expenseType)}>
                                                            <span className="far fa-file-alt"></span>
                                                        </a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <button className="btn btn-link" type="button"
                                                            onClick={() => openDeleteModal(expenseType)}>
                                                        <span className="far fa-trash-alt"></span>
                                                    </button>
                                                </td>

                                            </tr>
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Modal show={isToShowAddModal} centered size={"lg"}
                               onHide={() => setIsToShowAddModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Tipo De Despesa</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form className="row g-3 card-body needs-validation"
                                      onSubmit={addExpenseType}>
                                    <div className="col-md-6">
                                        <label className="form-label">Descrição:</label>
                                        <input className="form-control" type="text"
                                               value={description}
                                               onChange={event => setDescription(event.target.value)}/>
                                    </div>
                                    <div className="col-md-8">
                                        <label className="form-label">Complemento:</label>
                                        <input className="form-control" type="text"
                                               value={complement}
                                               onChange={event => setComplement(event.target.value)}/>
                                    </div>

                                    <div className="col-12">
                                        {id == null ?
                                            <button className="btn btn-primary" type="submit">Salvar</button>
                                            :
                                            <button className="btn btn-primary" onClick={updateExpenseType}>Salvar</button>
                                        }
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>

                        <Modal show={isToShowDeleteModal}
                               onHide={() => setIsToShowDeleteModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Você realmente deseja remover o Tipo de Despesa?</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer>
                                <Button variant="secondary"
                                        onClick={() => setIsToShowDeleteModal(false)}>
                                    Cancelar
                                </Button>
                                <Button variant="danger" onClick={deleteExpenseType}>
                                    Remover
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </div>
                </div>
            </div>
        </main>
    );

}