export default function Logo() {
    return (
        <div className="d-flex align-items-center">
            <a className="navbar-brand" href="/sigo/inicio">
                <div className="d-flex align-items-center py-3">
                    <span className="font-sans-serif">Sigo</span>
                </div>
            </a>
        </div>
    );
}