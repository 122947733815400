import Usuariologado from "../../componentes/layoutBase/usuariologado";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import Loading from "../../componentes/loading";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Paciente from "../../componentes/paciente";

export default function Inicio() {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [pacientes, setPacientes] = useState([]);
    const [idDoPacienteSelecionado, setIdDoPacienteSelecionado] = useState('');
    const [reminders, setReminders] = useState([]);

    useEffect(() => {
        obterUrlDaLogo();
        getReminders();
    }, []);

    function getReminders() {
        api.get("/reminders", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setReminders(resposta.data);
            })
            .catch((erro) => {
                console.error(erro);
            });

    }

    function obterUrlDaLogo() {
        api.get("/logo", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                sessionStorage.setItem('url_logo', resposta.data);
            })
            .catch((erro) => {
                console.error(erro);
            });

    }

    function cadastrarNovoPaciente() {
        navigate("/paciente");
    }

    const irParaProntuario = (event) => {
        event.preventDefault();
        const dadosDoPaciente = {
            "pacienteId": idDoPacienteSelecionado
        };
        navigate("/prontuario", {state: dadosDoPaciente});
    }

    const selecionarPaciente = (event) => {
        setIdDoPacienteSelecionado(event.target.value);
    }

    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content">
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            <h5 className="mb-0 d-flex justify-content-center texto-negrito"
                                data-anchor="data-anchor">Bem Vindo(a) ao
                                SIGO </h5>
                            <p className="mt-2 mb-0 d-flex justify-content-center">Sistema de Gestão Odontológica</p>
                        </div>
                        <div className="card-body bg-light">
                            <form className="row g-3 card-body">
                                <div className="col-md-4 m-4 mb-4 mt-4">
                                    <div className="tab-pane preview-tab-pane active" role="tabpanel">
                                        <Paciente pacienteSelecionado={idDoPacienteSelecionado}
                                                  selecionarPaciente={selecionarPaciente}
                                                  semLabel={true}/>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4 mt-4">
                                    <button className="btn btn-primary me-1 mb-1"
                                            onClick={event => irParaProntuario(event)}>Ir Para Prontuário
                                    </button>
                                </div>
                                <div className="col-md-3 mb-4 mt-4">
                                    <a className="btn btn-secondary mb-4" onClick={cadastrarNovoPaciente}>Novo
                                        Paciente</a>
                                </div>
                            </form>
                        </div>
                    </div>


                    <div className="row g-0">
                        <div>
                            <div className="card h-lg-100 ">
                                <div className="card-body p-0">
                                    <div className="table-responsive scrollbar" style={{maxHeight: "300px"}}>
                                        <table className="table table-dashboard mb-0 table-borderless fs--1 border-200">
                                            <thead className="bg-light">
                                            <tr className="text-900 ">
                                                <th>Lembretes</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr></tr>
                                            {reminders.map((reminder) => {
                                                    return <tr className="border-bottom border-200">
                                                        <td>
                                                            <div className="d-flex align-items-center position-relative">
                                                                <span
                                                                    className={reminder.type === 'Próximas Consultas' ? 'fas fa-phone-volume' : 'fas fa-birthday-cake'}/>
                                                                <div className="flex-1 ms-3">
                                                                    <h6 className="mb-1 fw-semi-bold">
                                                                        <a className="text-dark stretched-link">{reminder.title}</a>
                                                                    </h6>
                                                                    <p className="text-500 fs--2 mb-0">{reminder.subTitle}</p>
                                                                    <p className="fw-semi-bold mb-0 text-500">{reminder.type}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </main>
    );
}