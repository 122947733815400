import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Inicio from "./paginas/inicio";
import Dentista from "./paginas/dentista";
import Login from "./paginas/login";
import Clinica from "./paginas/clinica";
import ListaDePacientes from "./paginas/listaDePacientes";
import Paciente from "./paginas/paciente";
import Agenda from "./paginas/agenda";
import DadosGeraisDoPaciente from "./paginas/prontuario.dadosGerais";
import TratamentosDoPaciente from "./paginas/prontuario.tratamento";
import AnamneseDoPaciente from "./paginas/prontuario.anamnese";
import TesteDeImpressaoDeTratamento from "./componentes/testeDeImpressaoDeTratamento";
import CamposDoTratamentoParaImpressao from "./paginas/impressoes.tratamento";
import DocumentosDoPaciente from "./paginas/prontuario.documentos";
import EvolucoesGeraisDoPaciente from "./paginas/prontuario.evolucoesGerais";
import Expense from "./paginas/financial.expense";
import AlteracaoDeSenha from "./paginas/usuario.alterarSenha";
import Configurations from "./paginas/financial.configurations";
import ExpenseType from "./paginas/financial.configurations.expensetype";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Inicio/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/inicio" element={<Inicio/>}/>
                <Route path="/dentista" element={<Dentista/>}/>
                <Route path="/cliente" element={<Clinica/>}/>
                <Route path="/pacientes" element={<ListaDePacientes/>}/>
                <Route path="/paciente" element={<Paciente/>}/>
                <Route path="/prontuario" element={<DadosGeraisDoPaciente/>}/>
                <Route path="/tratamento" element={<TratamentosDoPaciente/>}/>
                <Route path="/anamnese" element={<AnamneseDoPaciente/>}/>
                <Route path="/documentos" element={<DocumentosDoPaciente/>}/>
                <Route path="/evolucoesgerais" element={<EvolucoesGeraisDoPaciente/>}/>
                <Route path="/agenda" element={<Agenda/>}/>
                <Route path="/testeDeImpressaoDeTratamento" element={<TesteDeImpressaoDeTratamento/>}/>
                <Route path="/configuracao-de-impressao-do-tratamento" element={<CamposDoTratamentoParaImpressao/>}/>
                <Route path="/alteracao-de-senha" element={<AlteracaoDeSenha/>}/>
                <Route path="/despesa" element={<Expense/>}/>
                <Route path="/configuracoes-financeiras" element={<Configurations/>}/>
                <Route path="/tipo-de-despesa" element={<ExpenseType/>}/>
                <Route path="*" element={<Login/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default App;
