import {Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

export default function ModalAvisoSemPermissao() {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const navigate = useNavigate();

    const [exibeModal, setExibeModal] = useState(false);
    const [ehUsuarioEssencial, setEhUsuarioEssencial] = useState(false);

    useEffect(() => {
        obterPlanoDoUsuario();
    }, []);

    function obterPlanoDoUsuario() {
        api.get("/usuario/plano", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        }).then((resposta) => {
            if (resposta.data === 'ESSENCIAL') {
                setEhUsuarioEssencial(true);
                setExibeModal(true);
            }
        }).catch((erro) => {
            console.error(erro);
        });
    }

    function fecharModal() {
        setExibeModal(false);
        navigate("/inicio");
    }

    return (
        <Modal show={exibeModal} centered
               onHide={() => fecharModal()}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <p>Atualmente o seu plano escolhido é o Essencial.</p>
                <p>Infelizmente ele não possui acesso a essa funcionalidade.</p>
                <p>Entre em contato com nosso Suporte para atualizar seu plano!</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-falcon-info" type="button" onClick={() => fecharModal()}>
                    Fechar
                </button>
            </Modal.Footer>
        </Modal>
    );
}