import Loading from "../../componentes/loading";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import Usuariologado from "../../componentes/layoutBase/usuariologado";
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import axios from "axios";
import {useEffect, useState} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {Modal} from "react-bootstrap";
import Paciente from "../../componentes/paciente";
import Dentista from "../../componentes/dentista";
import DatePicker from "react-date-picker";
import InputMask from "react-input-mask";
import StatusDaConsulta from "../../componentes/statusDaConsulta";
import TodosStatusDeConsulta from "../../componentes/todosStatusDeConsulta";
import {useNavigate} from "react-router-dom";

export default function Agenda() {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [exibeMensagemDeSucesso, setExibeMensagemDeSucesso] = useState(false);
    const [mensagemDeSucesso, setMensagemDeSucesso] = useState('');
    const [exibeModalParaAdicionarConsulta, setExibeModalParaAdicionarConsulta] = useState(false);
    const [consultas, setConsultas] = useState([]);

    // ### DADOS DA CONSULTA
    const [idDaConsultaSelecionada, setIdDaConsultaSelecionada] = useState('');
    const [idDoPacienteSelecionado, setIdDoPacienteSelecionado] = useState('');
    const [idDoDentistaSelecionado, setIdDoDentistaSelecionado] = useState('');
    const [dataDaConsulta, setDataDaConsulta] = useState('');
    const [horaDeInicioDaConsulta, setHoraDeInicioDaConsulta] = useState('');
    const [duracaoDaConsulta, setDuracaoDaConsulta] = useState('');
    const [observacoesDaConsulta, setObservacoesDaConsulta] = useState('');
    const [tipoDaConsulta, setTipoDaConsulta] = useState('');
    const [statusDaConsulta, setStatusDaConsulta] = useState('');

    useEffect(() => {
        obterConsultas();
    }, []);

    function obterConsultas() {
        setLoading(true);
        api.get("/consulta", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                const consultasConvertidas = resposta.data.map((consulta) => {
                    return {
                        "title": consulta.nomeDoPaciente,
                        "start": consulta.dataEHoraDeInicio,
                        "end": consulta.dataEHoraDeFim,
                        "consultaId": consulta.id,
                        "backgroundColor": obterCorDeFundoDaConsulta(consulta.status)
                    }
                });
                setConsultas(consultasConvertidas);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.error("ops! ocorreu um erro" + err);
            });
    }

    function obterCorDeFundoDaConsulta(status) {
        if (status === 'CONFIRMADA') {
            return "rgb(88 115 251)";
        }
        if (status === 'DESMARCADA') {
            return "rgb(139 23 147)";
        }
        if (status === 'REALIZADA') {
            return "#b5fb91";
        }
        if (status === 'REAGENDADA') {
            return "rgb(249 178 49)";
        }
    }

    const selecionarPaciente = (event) => {
        setIdDoPacienteSelecionado(event.target.value);
    }

    const selecionarDentista = (event) => {
        setIdDoDentistaSelecionado(event.target.value);
    }

    const adicionarConsulta = (event) => {
        const horaDaConsulta = String(event.date.getHours()).padStart(2, '0');
        const minutoDaConsulta = String(event.date.getMinutes()).padStart(2, '0');
        setDataDaConsulta(event.date);
        setHoraDeInicioDaConsulta(horaDaConsulta.concat(":").concat(minutoDaConsulta));
        setExibeModalParaAdicionarConsulta(true);
    }

    function abrirConsulta(event) {
        setLoading(true);
        api.get("/consulta/".concat(event.event.extendedProps.consultaId), {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setIdDaConsultaSelecionada(resposta.data.id);
                setIdDoPacienteSelecionado(resposta.data.pacienteId);
                setIdDoDentistaSelecionado(resposta.data.dentistaId);
                setDataDaConsulta(resposta.data.dataEHoraDeInicioParaCalendario);
                setHoraDeInicioDaConsulta(resposta.data.hora);
                setDuracaoDaConsulta(resposta.data.duracao);
                setObservacoesDaConsulta(resposta.data.observacao);
                setTipoDaConsulta(resposta.data.tipo);
                setStatusDaConsulta(resposta.data.status);
                setExibeModalParaAdicionarConsulta(true);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.error("ops! ocorreu um erro" + err);
            });
    }

    const cadastrarConsulta = (event) => {
        event.preventDefault();
        setExibeModalParaAdicionarConsulta(false);
        setLoading(true);
        const consulta = {
            "pacienteId": idDoPacienteSelecionado,
            "dentistaId": idDoDentistaSelecionado,
            "data": dataDaConsulta,
            "hora": horaDeInicioDaConsulta,
            "duracao": duracaoDaConsulta,
            "observacao": observacoesDaConsulta,
            "tipo": tipoDaConsulta
        };

        api.post("/consulta", consulta, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setExibeMensagemDeSucesso(true);
                setMensagemDeSucesso('Consulta cadastrada com sucesso!');
                obterConsultas();
                limparCamposDaModalDeCadastroDeConsulta();
            })
            .catch((erro) => {
                setLoading(false);
                console.error(erro);
            });
    }

    function limparCamposDaModalDeCadastroDeConsulta() {
        setIdDaConsultaSelecionada('');
        setIdDoPacienteSelecionado('');
        setIdDoDentistaSelecionado('');
        setDataDaConsulta('');
        setHoraDeInicioDaConsulta('');
        setDuracaoDaConsulta('');
        setObservacoesDaConsulta('');
        setTipoDaConsulta('');
    }

    function fecharModalParaAdicionarConsulta() {
        setExibeModalParaAdicionarConsulta(false);
        limparCamposDaModalDeCadastroDeConsulta();
    }

    function confirmarConsulta() {
        setExibeModalParaAdicionarConsulta(false);
        setLoading(true);
        api.put("/consulta/confirmar/".concat(idDaConsultaSelecionada), null, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setExibeMensagemDeSucesso(true);
                setMensagemDeSucesso('Consulta confirmada com sucesso!');
                obterConsultas();
                limparCamposDaModalDeCadastroDeConsulta();
            })
            .catch((erro) => {
                setLoading(false);
                console.error(erro);
            });
    }

    function reagendarConsulta() {
        setExibeModalParaAdicionarConsulta(false);
        setLoading(true);
        api.put("/consulta/reagendar/".concat(idDaConsultaSelecionada), null, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setExibeMensagemDeSucesso(true);
                setMensagemDeSucesso('Consulta reagendada com sucesso!');
                obterConsultas();
                limparCamposDaModalDeCadastroDeConsulta();
            })
            .catch((erro) => {
                setLoading(false);
                console.error(erro);
            });
    }

    function desmarcarConsulta() {
        setExibeModalParaAdicionarConsulta(false);
        setLoading(true);
        api.put("/consulta/desmarcar/".concat(idDaConsultaSelecionada), null, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setExibeMensagemDeSucesso(true);
                setMensagemDeSucesso('Consulta desmarcada com sucesso!');
                obterConsultas();
                limparCamposDaModalDeCadastroDeConsulta();
            })
            .catch((erro) => {
                setLoading(false);
                console.error(erro);
            });
    }

    function realizarConsulta() {
        setExibeModalParaAdicionarConsulta(false);
        setLoading(true);
        api.put("/consulta/realizar/".concat(idDaConsultaSelecionada), null, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setExibeMensagemDeSucesso(true);
                setMensagemDeSucesso('Consulta realizada com sucesso!');
                obterConsultas();
                limparCamposDaModalDeCadastroDeConsulta();
            })
            .catch((erro) => {
                setLoading(false);
                console.error(erro);
            });
    }

    function irParaProntuarioDoPaciente(pacienteId) {
        const paciente = {
            "pacienteId": pacienteId
        };
        navigate("/prontuario", {state: paciente});
    }

    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content" hidden={isLoading}>
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            {exibeMensagemDeSucesso &&
                                <MensagemDeSucesso mensagem={mensagemDeSucesso}/>}
                            <div className="row justify-content-between align-items-center">
                                <div className="col">
                                    <div className="d-flex">
                                        <h5 className="texto-negrito">Agenda</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light">
                            <TodosStatusDeConsulta/>
                            <div className="card-body border-top p-0">
                                <div className="card-header">
                                    <div className="row gx-0 align-items-center">
                                        <div className="col d-flex justify-content-end order-md-2">
                                            <div className="dropdown font-sans-serif me-md-2">
                                                <span>visualizar por:</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body p-0 scrollbar">
                                    <FullCalendar plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                                  initialView="timeGridWeek" locale="pt-br"
                                                  nowIndicator={true}
                                                  editable={true}
                                                  headerToolbar={{
                                                      left: 'prev next today',
                                                      center: 'title',
                                                      right: 'dayGridMonth timeGridWeek timeGridDay'
                                                  }}
                                                  buttonText={{
                                                      month: 'Mês',
                                                      week: 'Semana',
                                                      day: 'Dia',
                                                      today: 'Hoje'
                                                  }}
                                                  allDayText={"Dia Inteiro"}
                                                  eventClick={event => abrirConsulta(event)}
                                                  events={consultas}
                                                  dateClick={event => adicionarConsulta(event)}/>
                                </div>

                                <Modal show={exibeModalParaAdicionarConsulta} centered size={"lg"}
                                       onHide={() => fecharModalParaAdicionarConsulta()}>
                                    <Modal.Header closeButton>
                                        <Modal.Title hidden={idDaConsultaSelecionada > 0}>Consulta</Modal.Title>
                                        <div class="d-flex align-items-center m-3">
                                            <button className="btn btn-falcon-primary btn-sm"
                                                    hidden={!idDaConsultaSelecionada > 0}
                                                    onClick={() => confirmarConsulta()}>Confirmar
                                            </button>
                                        </div>
                                        <div class="d-flex align-items-center m-3">
                                            <button className="btn btn-falcon-primary btn-sm"
                                                    hidden={!idDaConsultaSelecionada > 0}
                                                    onClick={() => reagendarConsulta()}>Reagendar
                                            </button>
                                        </div>
                                        <div class="d-flex align-items-center m-3">
                                            <button className="btn btn-falcon-primary btn-sm"
                                                    hidden={!idDaConsultaSelecionada > 0}
                                                    onClick={() => desmarcarConsulta()}>Desmarcar
                                            </button>
                                        </div>
                                        <div class="d-flex align-items-center m-3">
                                            <button className="btn btn-falcon-primary btn-sm"
                                                    hidden={!idDaConsultaSelecionada > 0}
                                                    onClick={() => realizarConsulta()}>Realizar
                                            </button>
                                        </div>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <form className="row g-3 card-body needs-validation" action="#"
                                              onSubmit={cadastrarConsulta}>
                                            <div className="col-md-8">
                                                <Paciente pacienteSelecionado={idDoPacienteSelecionado}
                                                          selecionarPaciente={selecionarPaciente}/>
                                            </div>
                                            <div className="col-md-2">
                                                <StatusDaConsulta status={statusDaConsulta}/>
                                            </div>
                                            <div className="col-md-8">
                                                <Dentista dentistaSelecionado={idDoDentistaSelecionado}
                                                          selecionarDentista={selecionarDentista}/>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label">Data</label>
                                                <DatePicker value={dataDaConsulta} className="form-control"
                                                            onChange={(date) => setDataDaConsulta(date)}
                                                            format="dd/MM/yyyy"></DatePicker>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="form-label">Hora de Início</label>
                                                <InputMask className="form-control" mask="99:99"
                                                           value={horaDeInicioDaConsulta}
                                                           onChange={event => setHoraDeInicioDaConsulta(event.target.value)}></InputMask>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="form-label">Duração(min)</label>
                                                <input className="form-control" type="number" value={duracaoDaConsulta}
                                                       onChange={event => setDuracaoDaConsulta(event.target.value)}/>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Observações</label>
                                                <textarea className="form-control" rows="3"
                                                          value={observacoesDaConsulta}
                                                          onChange={event => setObservacoesDaConsulta(event.target.value)}></textarea>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">Tipo</label>
                                                <select className="form-select" value={tipoDaConsulta}
                                                        onChange={event => setTipoDaConsulta(event.target.value)}>
                                                    <option value="INVALIDO">Selecione o tipo</option>
                                                    <option value="AVALIACAO">Avaliação</option>
                                                    <option value="CIRURGIA">Cirurgia</option>
                                                    <option value="DENTISTICA">Dentística</option>
                                                    <option value="ENDODONTIA">Endodontia</option>
                                                    <option value="HOF">HOF</option>
                                                    <option value="PROTESE">Prótese</option>
                                                    <option value="RETORNO">Retorno</option>
                                                    <option value="ODONTOPEDIATRA">Odontopediatra</option>
                                                    <option value="ORTODONTIA">Ortodontia</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <a className="btn btn-link mt-4"
                                                onClick={() => irParaProntuarioDoPaciente(idDoPacienteSelecionado)}>Ir para prontuário do paciente</a>
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-primary" type="submit"
                                                        hidden={idDaConsultaSelecionada > 0}>Agendar
                                                </button>
                                            </div>
                                        </form>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}